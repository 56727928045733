numCh = function(){
    // getId = document.getElementById("alcaldias-representante-filter");
    // getBtn = getId.getElementsByClassName("btn-filter__display");
    // getBtn[0].innerHTML = "{{ countData['total_alcaldias_disp'] }}";
    $.ajax({
	type: 'GET',
	url: base_url + '/tableroEmergencias/numeralia',
	dataType: "json",
	success: function(response){

		$("#conectividad").html(response.conectividad);

	    Object.keys(response).forEach(function(i){
		getId = document.getElementById(i);


		if (i!=="red-alterna-filter"){
			getBtn = getId.getElementsByClassName("btn-filter__display");
			
			if(getBtn[0])
			{
				getBtn[0].innerHTML = response[i];
			}
		} else {
		    getDiv = getId.getElementsByTagName('div');
		    getDiv[0].innerHTML = response[i];
		}
	    });
	}
    });
    setTimeout(numCh, 30000);
};

if($(location).attr('pathname')=='/tablero-emergencia')
{
	numCh();
}