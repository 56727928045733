
$(document).on('change', '#borrar', function (event) {
    event.preventDefault();
    var clase = $(this).attr('data-class');
    console.log('clase: ' + clase);
    if ($(this).is(':checked')) {
        $('.borra_registro').removeClass('d-none');
        $('.cancelar').removeClass('d-none');
    } else {
        $('.borrar').prop("checked", false);
        $('.borra_registro').addClass('d-none');
        $('.cancelar').addClass('d-none');

    }
});
$(document).ready(function(){
    codigo_postal = $('#cp').val();
    console.log("document ready get cp: "+codigo_postal);
    //getCodigo(codigo_postal);
});


$(document).ready(function () {
    $('#cancelar').click(function (evento) {
        $('.borrar').prop("checked", false);
        $('.borra_registro').addClass('d-none');
        $('.cancelar').addClass('d-none');
    });

    //Abrir y cerrar el div de Password y Telefono
    $('#editar').on('click', function () {
        $('#cambio_password').removeClass('d-none');
        $('#cerrar').removeClass('d-none');
        $('#editar').addClass('d-none');
    });
    $('#cerrar').on('click', function () {
        $('#cambio_password').addClass('d-none');
        $('#cerrar').addClass('d-none');
        $('#editar').removeClass('d-none');
    });
});

$(document).ready(function () {

    window.crearMensaje = function (error, titulo, mensaje, tiempo = 3000) {
        var clase_mensaje = error == true ? "alert-danger" : "alert-success";
        var mensaje_alert = '<div class="alertaActivacion alert msj_js ' + clase_mensaje + '">';
        mensaje_alert += '<strong id="mensaje_negritas" style="font-size:16px;">' + titulo + '</strong>';
        mensaje_alert += '<p id="mensaje" style="font-size:14px;">' + mensaje + '</p>';
        mensaje_alert += '</div>';
        $("body").append(mensaje_alert);
        $(".msj_js").show();
        setTimeout(function () {
            $(".msj_js").remove();
        }, tiempo);
    }
    //console.log(datos_horario_usuario);
    if (1) {

        var cambiar_contrasenia = false;
        var cambiar_telefono = 0;


        $("#btn_cambiar_contrasenia").click(function () {
            if (cambiar_contrasenia) {
                var contrasenia_actual = $("#password_old").val();
                var contrasenia_nueva = $("#password_new").val();
                var contrasenia_nueva_otra_vez = $("#password_new_again").val();

                if (contrasenia_actual.length >= 8 && contrasenia_actual.length <= 12 && contrasenia_nueva.length >= 8 && contrasenia_nueva.length <= 12) {
                    if (contrasenia_nueva === contrasenia_nueva_otra_vez) {
                        $.ajax({
                            type: 'POST',
                            url: base_url + '/Perfil/ActualizarContraseña',
                            headers: {
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            },
                            data: ({
                                _method: 'PUT',
                                contrasenia_actual: contrasenia_actual,
                                contrasenia_nueva: contrasenia_nueva,
                                contrasenia_nueva_otra_vez: contrasenia_nueva_otra_vez,
                            }),
                            success: function (data) {
                                if (data.code == 200) {
                                    window.location.href = base_url + "/Perfil";
                                } else {
                                    crearMensaje(true, "Error", data.response);
                                }
                            },
                            error: function (jqXhr, textStatus, errorThrown) {
                                console.log(errorThrown);
                            }
                        });
                    } else {
                        crearMensaje(true, "Error", "La contraseña nueva ingresada no coincide con la de Confirma contraseña", 5000);
                    }
                } else {
                    crearMensaje(true, "Error", "La contraseña actual y la nueva deben de tener al menos 8 caracteres y no más de 12 caracteres", 5000);
                }
            } else {
                cambiar_contrasenia = true;
                $("#cambiar_contrasenia_fields").removeClass('d-none');
                $(this).text('Guardar Contraseña');
                $("#btn_cancelar_cambios_contrasenia_telefono").removeClass('d-none');
                $("#btn_cambiar_telefono").addClass('d-none');
            }
        });

        $("#btn_cancelar_cambios_contrasenia_telefono").click(function () {
            $("#password_old").val("");
            $("#password_new").val("");
            $("#password_new_again").val("");

            cambiar_contrasenia = false;
            cambiar_telefono = 0;

            $("#cambiar_contrasenia_fields").addClass('d-none');
            $("#cambiar_telefono_fields").addClass('d-none');

            $("#btn_cambiar_contrasenia").text('Cambiar Contraseña');
            $("#btn_cambiar_telefono").text('Cambiar Telefono');


            $("#btn_cancelar_cambios_contrasenia_telefono").addClass('d-none');
            $("#btn_cambiar_telefono").removeClass('d-none');
            $("#btn_cambiar_contrasenia").removeClass('d-none');

            $("#col_codigo_sms").addClass('d-none');
            $("#btn_validar_codigo_update").addClass('d-none');
        });


        $("#btn_cambiar_telefono").click(function () {
		var contador_update = sessionStorage.getItem("contador_update")==null?null:parseInt(sessionStorage.getItem("contador_update"));
            switch (cambiar_telefono) {
                //caso de presionar el boton de cambiar telefono por primera vez
                case 0:
                    $("#cambiar_telefono_fields").removeClass('d-none');
                    $("#btn_cambiar_contrasenia").addClass('d-none');
                    $("#btn_cancelar_cambios_contrasenia_telefono").removeClass('d-none');
                    $(this).text('Enviar Código SMS');
                    cambiar_telefono = 1;
                    if (contador_update != null && contador_update > 0) {
                        $("#btn_cambiar_telefono").css('pointer-events', 'none');
                        $("#btn_cambiar_telefono").attr("disabled", true);
                        var interval = setInterval(function () {
                            $("#btn_cambiar_telefono").text("Reenviar nuevamente en " + contador_update);
                            contador_update--;
                            sessionStorage.setItem("contador_update", contador_update);
                            if (contador_update === 0) {
                                $("#btn_cambiar_telefono").css('pointer-events', 'auto');
                                $("#btn_cambiar_telefono").attr("disabled", false);
                                $("#btn_cambiar_telefono").text("Enviar Código SMS");
                                clearInterval(interval);
                            }
                        }, 1000);
                    }
                    break;
                case 1:
                    var telefono_actual = $('#telefono_actual').val();
                    var telefono_nuevo = $('#telefono_nuevo').val();
                    telefono_nuevo = telefono_nuevo.replace(/[^0-9]/g, '');
                    $("#telefono_nuevo").val(telefono_nuevo);
                    if (telefono_actual.length > 0) {
                        if (telefono_actual == telefono_usuario) {
                            if (telefono_nuevo.length == 10) {
                                if (telefono_actual != telefono_nuevo) {
                                    if (contador_update === null || contador_update <= 0) {
                                        contador_update = 0;
                                        sessionStorage.setItem("contador_update", 60);
                                    }

                                    if (contador_update == 0) {
                                        var d = new Date();
                                        d = d.getTime();
                                        $.ajax({
                                            type: 'POST',
                                            url: base_url + '/Perfil/ActualizarTelefono/enviarSMS',
                                            headers: {
                                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                                            },
                                            data: ({
                                                telefono_actual: telefono_actual,
                                                telefono_nuevo: telefono_nuevo,
                                                date: d
                                            }),
                                            success: function (data) {
                                                if (data.code == 200) {
                                                    $("#col_codigo_sms").removeClass('d-none');
                                                    $("#btn_validar_codigo_update").removeClass('d-none');
                                                    contador_update = 60;
                                                    crearMensaje(false, "Correcto", data.response, 8000);
                                                    $("#btn_cambiar_telefono").css('pointer-events', 'none');
                                                    $("#btn_cambiar_telefono").attr("disabled", true);
                                                    var interval = setInterval(function () {
                                                        $("#btn_cambiar_telefono").text("Reenviar nuevamente en " + contador_update);
                                                        contador_update--;
                                                        sessionStorage.setItem("contador_update", contador_update);
                                                        if (contador_update === 0) {
                                                            $("#btn_cambiar_telefono").css('pointer-events', 'auto');
                                                            $("#btn_cambiar_telefono").attr("disabled", false);
                                                            $("#btn_cambiar_telefono").text("Reenviar Código");
                                                            clearInterval(interval);
                                                        }
                                                    }, 1000);
                                                } else {
                                                    contador_update = 0;
                                                    sessionStorage.setItem("contador_update", 0);
                                                    crearMensaje(true, "Error", data.response, 5000);
                                                }
                                            },
                                            error: function (jqXhr, textStatus, errorThrown) {
                                                console.log(errorThrown);
                                            }
                                        });
                                    } else {
                                        crearMensaje(true, "Error", "Espere el tiempo indicado antes de poder enviar nuevamente el mensaje", 5000);
                                    }
                                } else {
                                    crearMensaje(true, "Error", "El número celular nuevo ingresado tiene que ser diferente al número celular actual", 5000);
                                }
                            } else {
                                crearMensaje(true, "Error", "El número celular nuevo ingresado (" + telefono_nuevo + ") debe tener 10 números", 5000);
                            }
                        } else {
                            crearMensaje(true, "Error", "El número celular actual no coincide", 5000);
                        }
                    } else {
                        crearMensaje(true, "Error", "El número celular actual no puede estar vacio", 5000);
                    }

                    break;
                default:
                    $("#btn_cancelar_cambios_contrasenia_telefono").click();
                    break;
            }
        });

        $("#btn_validar_codigo_update").click(function () {
            var codigo = $("#codigo_sms").val();
            if (codigo == "" || codigo.length < 4) {
                crearMensaje(true, "Error", "El código no puede estar vacio ni ser menor a 4 carácteres", 5000);
            } else {
                $.ajax({
                    type: 'POST',
                    url: base_url + '/Perfil/ActualizarTelefono',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: ({
                        _method: 'PUT',
                        codigo: codigo
                    }),
                    success: function (data) {
                        if (data.code == 200) {
                            sessionStorage.clear();
                            window.location.href = base_url + "/Perfil";
                        } else {
                            crearMensaje(true, "Error", data.message, 5000);
                        }
                    },
                    error: function (jqXhr, textStatus, errorThrown) {
                        console.log(errorThrown);
                    }
                });
            }
        });

    }
});