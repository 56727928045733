$(window).ready(function(){

	//console.log(datos_horario_usuario);
	if(window.location.href===base_url+"/tablero-emergencia" || window.location.href===base_url+"/tablero-emergencia#"){
		window.personas_buscar_nombre = [];
		window.personas_buscar_telefono = [];
		var card_usuario_responsable = "";

		$(document).on('click', '.type-recipients .form-check', function(){
    		var recipientsList = $(this).attr('data-recipients');
	    	$('.recipients-names').removeClass('active');
	    	$('.recipient-'+recipientsList).addClass('active');
	    	$('.type-recipients li').removeClass('active');
	    	$(this).parent().addClass('active');
	    });

		$("#messajes-recipients").on('click', function(event){
			event.preventDefault();
			$(".container-list-recipients").empty();
			$("#container-recipients-names").empty();

            setRecipientsUsersTest();
            setRecipientsUsersSpecialized();
            setRecipientsUsersFixed();
            setRecipientsUsersMobile();
			setRecipientsUsersPromoters();
			//setRecipientsUsersBusiness();
			setRecipientsUsersResponsible();
			// setRecipientsUsersAlcaldes();
		});

		/**
		 * SENTIKA RESPONSIBLE
		 */
		function setRecipientsUsersResponsible(){
			var recipients_m = Tablero.recipients.usuario_responsable;

			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                '<div class="form-check" data-recipients="responsible">'+
                    '<input type="checkbox" class="form-check-input" id="users_responsible_message"  >'+
                    '<label class="form-check-label" for="">Responsable de Proteccion Civíl</label>'+
                '</div>'+
            '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-responsible active"><ul>';
			recipients += '<li>'+
                '<div class="form-check">'+
                    '<input type="checkbox" class="form-check-input usuarios_responsables_checkbox"   value="'+recipients_m.telefono+'" id="responsible-1">'+
                    '<label class="form-check-label" for="responsible-1">'+recipients_m.nombre+'</label>'+
                '</div>'+
            '</li>';
            window.personas_buscar_nombre.push(recipients_m.nombre);
			window.personas_buscar_telefono.push(recipients_m.telefono);
			recipients += '</ul></div>';
			$('#container-recipients-names').append(recipients);
		}

		/**
		 * SENTIKA BUSINESS
		 */
		function setRecipientsUsersBusiness(){
			var recipients_m = Tablero.recipients.usuarios_empresas;

			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                '<div class="form-check" data-recipients="business">'+
                    '<input type="checkbox" class="form-check-input" id="users_business_message">'+
                    '<label class="form-check-label" for="">Empresas de Reconstrucción</label>'+
                '</div>'+
            '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-business"><ul>';
				for(let i=0;i<recipients_m.length;i++){
					recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input usuarios_business_checkbox" value="'+recipients_m[i].telefono+'" id="business-'+i+'">'+
                            '<label class="form-check-label" for="business-'+i+'">'+recipients_m[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';
                    window.personas_buscar_nombre.push(recipients_m[i].nombre);
					window.personas_buscar_telefono.push(recipients_m[i].telefono);
				}
			recipients += '</ul></div>';
			$('#container-recipients-names').append(recipients);
		}

		/**
		 * SENTIKA PROMOTERS
		 */
		function setRecipientsUsersPromoters(){
			var recipients_m = Tablero.recipients.usuarios_promotores;

			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                '<div class="form-check" data-recipients="promoters">'+
                    '<input type="checkbox" class="form-check-input" id="users_promoters_message">'+
                    '<label class="form-check-label" for="">Promotores</label>'+
                '</div>'+
            '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-promoters"><ul>';
				for(let i=0;i<recipients_m.length;i++){
					recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input users_promoters_checkbox" value="'+recipients_m[i].telefono+'" id="promoters-'+i+'">'+
                            '<label class="form-check-label" for="promoters-'+i+'">'+recipients_m[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';
                    window.personas_buscar_nombre.push(recipients_m[i].nombre);
					window.personas_buscar_telefono.push(recipients_m[i].telefono);
				}
			recipients += '</ul></div>';
			$('#container-recipients-names').append(recipients);
		}

		/**
		 * SENTIKA MOBILE
		 */
		function setRecipientsUsersMobile(){
			var recipients_m = Tablero.recipients.usuarios_sentika_moviles;

			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                    '<div class="form-check" data-recipients="mobile">'+
                        '<input type="checkbox" class="form-check-input" id="users_mobile_message">'+
                        '<label class="form-check-label" for="">Voluntario Sentika Moviles</label>'+
                    '</div>'+
                '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-mobile"><ul>';
				for(let i=0;i<recipients_m.length;i++){
					recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input users_mobile_checkbox" value="'+recipients_m[i].telefono+'" id="mobile-'+i+'">'+
                            '<label class="form-check-label" for="mobile-'+i+'">'+recipients_m[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';

                    window.personas_buscar_nombre.push(recipients_m[i].nombre);
					window.personas_buscar_telefono.push(recipients_m[i].telefono);
				}
			recipients += '</ul></div>';
			$('#container-recipients-names').append(recipients);
		}

		/**
		 * SENTIKA FIXED
		 */
		function setRecipientsUsersFixed(){

			var recipients_m = Tablero.recipients.usuarios_sentika_fijos;
			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                    '<div class="form-check" data-recipients="fixed">'+
                        '<input type="checkbox" class="form-check-input" id="users_fixed_message">'+
                        '<label class="form-check-label" for="">Voluntario Sentika Fijos</label>'+
                    '</div>'+
                '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-fixed"><ul>';
				for(let i=0; i < recipients_m.length; i++){
                    recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input users_fixed_checkbox" value="'+recipients_m[i].telefono+'" id="fixed-'+i+'">'+
                            '<label class="form-check-label" for="fixed-'+i+'">'+recipients_m[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';
                    window.personas_buscar_nombre.push(recipients_m[i].nombre);
					window.personas_buscar_telefono.push(recipients_m[i].telefono);
				}
			recipients += '</ul></div>';

			$('#container-recipients-names').append(recipients);
		}

		/**
		 * SENTIKA SPECIALIZED
		 */
		function setRecipientsUsersSpecialized(){
			var recipients_m = Tablero.recipients.usuarios_sentika_especializados;

			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                    '<div class="form-check" data-recipients="specialized">'+
                        '<input type="checkbox" class="form-check-input" id="users_specialized_message">'+
                        '<label class="form-check-label" for="">Voluntario Sentika Especializado</label>'+
                    '</div>'+
                '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-specialized"><ul>';
				for(let i=0; i < recipients_m.length; i++){
                    recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input users_specialized_checkbox" value="'+recipients_m[i].telefono+'" id="specialized-'+i+'">'+
                            '<label class="form-check-label" for="specialized-'+i+'">'+recipients_m[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';
                    window.personas_buscar_nombre.push(recipients_m[i].nombre);
					window.personas_buscar_telefono.push(recipients_m[i].telefono);
				}
			recipients += '</ul></div>';
			$('#container-recipients-names').append(recipients);
		}

		/**
		 * USERS TEST
		 */
		function setRecipientsUsersTest(){
			var list = '<li>'+
                    '<div class="form-check" data-recipients="test">'+
                        '<input type="checkbox" class="form-check-input" id="users_test_message">'+
                        '<label class="form-check-label" for="">Usuarios de prueba</label>'+
                    '</div>'+
                '</li>';
            $('.container-list-recipients').append(list);
            var usuarios_pruebas = [
				{
					nombre:'Salvador Sanchez',
					telefono:'5515896101'
				},
				{
					nombre:'Fabian Avila',
					telefono:'5517987272'
				},
				{
					nombre:'Alejandra González',
					telefono:'8118794920'
				},
				{
					nombre:'Dulce Colin',
					telefono:'5575316690'
				},
				{
					nombre:'Rafa Cambranis',
					telefono:'9982402555'
				},
				{
					nombre:'Viri Hernández',
					telefono:'5528894745'
				}
			];

			var recipients = '<div class="recipients-names recipient-test"><ul>';
				for(let i=0;i<usuarios_pruebas.length;i++){
                    recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input users_test_checkbox" value="'+usuarios_pruebas[i].telefono+'" id="test-'+i+'">'+
                            '<label class="form-check-label" for="test-'+i+'">'+usuarios_pruebas[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';
				}
			recipients += '</ul></div>';
			$('#container-recipients-names').append(recipients);
		}

		/**
		 * SENTIKA ALCALDES
		 */
		function setRecipientsUsersAlcaldes(){
			var recipients_m = Tablero.recipients.usuarios_alcaldes;
			if(recipients_m.length == 0)
				return false;

			var list = '<li>'+
                    '<div class="form-check" data-recipients="alcaldes">'+
                        '<input type="checkbox" class="form-check-input" id="users_alcaldes_message">'+
                        '<label class="form-check-label" for="">Representantes de Jefatura ante las Alcaldías</label>'+
                    '</div>'+
                '</li>';
            $('.container-list-recipients').append(list);
			var recipients = '<div class="recipients-names recipient-alcaldes"><ul>';
				for(let i=0; i < recipients_m.length; i++){
                    recipients += '<li>'+
                        '<div class="form-check">'+
                            '<input type="checkbox" class="form-check-input users_alcaldes_checkbox" value="'+recipients_m[i].telefono+'" id="alcalde-'+i+'">'+
                            '<label class="form-check-label" for="alcaldes-'+i+'">'+recipients_m[i].nombre+'</label>'+
                        '</div>'+
                    '</li>';
                    window.personas_buscar_nombre.push(recipients_m[i].nombre);
					window.personas_buscar_telefono.push(recipients_m[i].telefono);
				}
			recipients += '</ul></div>';

			$('#container-recipients-names').append(recipients);
		}


		$(document).on('change',"#users_test_message",function(){
			if($(this).is(":checked")){
				$(".users_test_checkbox").prop('checked', true);
			}else{
				$(".users_test_checkbox").prop('checked', false);
			}
		});

		$(document).on('change',"#users_specialized_message",function(){
			if($(this).is(":checked")){
				$(".users_specialized_checkbox").prop('checked', true);
			}else{
				$(".users_specialized_checkbox").prop('checked', false);
			}
		});

		$(document).on('change',"#users_fixed_message",function(){
			if($(this).is(":checked")){
				$(".users_fixed_checkbox").prop('checked', true);
			}else{
				$(".users_fixed_checkbox").prop('checked', false);
			}
		});

		$(document).on('change',"#users_mobile_message",function(){
			if($(this).is(":checked")){
				$(".users_mobile_checkbox").prop('checked', true);
			}else{
				$(".users_mobile_checkbox").prop('checked', false);
			}
		});

		$(document).on('change',"#users_promoters_message",function(){
			if($(this).is(":checked")){
				$(".users_promoters_checkbox").prop('checked', true);
			}else{
				$(".users_promoters_checkbox").prop('checked', false);
			}
		});

		$(document).on('change',"#users_business_message",function(){
			if($(this).is(":checked")){
				$(".usuarios_business_checkbox").prop('checked', true);
			}else{
				$(".usuarios_business_checkbox").prop('checked', false);
			}
		});

		$(document).on('change',"#users_alcaldes_message",function(){
			if($(this).is(":checked")){
				$(".users_alcaldes_checkbox").prop('checked', true);
			}else{
				$(".users_alcaldes_checkbox").prop('checked', false);
			}
		});


		/* ccc */



		$(document).on('change',"#users_search_message",function(){
			if($(this).is(":checked")){
				$(".users_search_checkbox").prop('checked', true);
			}else{
				$(".users_search_checkbox").prop('checked', false);
			}
		});


		$("#btn_enviar_mensaje").click(function(){

			var mensaje = $("#textarea_mensaje").val();
			var user = $(this).data('u');
			if (mensaje===""){
				crearMensaje(true,"Vacio","No puede dejar el mensaje a enviar vacio",5000);
			}else{
				var contactos = [];
				var to = [];
				var usuarios_pruebas = $(".users_test_checkbox");
				var usuarios_alcaldes = $(".users_alcaldes_checkbox");
				//se agregan los usuarios de prueba
				usuarios_pruebas.each(function(indice,elemento){
					if(elemento.checked){
						contactos.push(elemento.value);
					}
				});

				usuarios_alcaldes.each(function(indice,elemento){
					if(elemento.checked){
						contactos.push(elemento.value);
					}
				});

				/*TODO agregar a los demas usuarios faltantes*/
				if(contactos.length===0){
					crearMensaje(true,"Sin contactos","Debe seleccionar almenos a 1 contacto para enviar el mensaje",5000);
				}else{

					$(".type-recipients").find('input:checked').each(function(indice,elemento){
						to.push($(this).parent().find('.form-check-label').text());
					});


					$.ajax({
						type: 'POST',
						url: base_url+'/tableroEmergencias/SendSMS',
						headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
						data: ({
							mensaje,
							contactos,
							user,
							to

						}),
						success: function(data){
							if(data.code===200){
								console.log(data.response);
								crearMensaje(false,"Correcto","Se ha enviado su mensaje a las personas seleccionadas correctamente",5000);
								clearSendMessage();
							}else{
								crearMensaje(true,"Error","Ha ocurrido un error al intentar enviar su mensaje, por favor intentelo de nuevo",5000);
							}
						},
						error: function( jqXhr, textStatus, errorThrown ){
							console.log( errorThrown );
						}
					});
				}
			}
		});

		$('.cancel-send-message').on('click', function(){
			clearSendMessage();
		});


		function clearSendMessage(){
			$("#textarea_mensaje").val('');
			$(".users_test_checkbox").prop('checked', false);
			$(".users_specialized_checkbox").prop('checked', false);
			$(".users_fixed_checkbox").prop('checked', false);
			$(".users_mobile_checkbox").prop('checked', false);
			$(".users_promoters_checkbox").prop('checked', false);
			$(".usuarios_business_checkbox").prop('checked', false);
			$(".users_alcaldes_checkbox").prop('checked', false);
			$(".users_search_checkbox").prop('checked', false);
			$('#users_fixed_message').prop('checked', false);
			$('#users_alcaldes_message').prop('checked', false);
			$('#users_test_message').prop('checked', false);
			$('#users_specialized_message').prop('checked', false);
			$('#users_mobile_message').prop('checked', false);
			$('#users_promoters_message').prop('checked', false);
			$('#users_business_message').prop('checked', false);
			$('#users_search_message').prop('checked', false);
			$('#search-recipient').val('');
			$('.recipient-search').remove();
			$('.list-search').remove();
		}

		$("#search-recipient").keyup(function(){
			var texto_ingresado = $(this).val();

			if(texto_ingresado != ""){
				$('.recipient-search').remove();
				$('.list-search').remove();

				$('.container-list-recipients li').removeClass('active');
				$('.recipients-names').removeClass('active');

				texto_ingresado = texto_ingresado.toLowerCase();
				var list = '<li class="list-search active">'+
                    '<div class="form-check" data-recipients="search">'+
                        '<input type="checkbox" class="form-check-input" id="users_search_message">'+
                        '<label class="form-check-label" for="">Usuarios Filtrados</label>'+
                    '</div>'+
                '</li>';

				$('.container-list-recipients').append(list);
				var arreglo_filtrado = window.personas_buscar_nombre.filter(item => item.indexOf(texto_ingresado) > -1);
				console.log(arreglo_filtrado.length);
				var recipients = '<div class="recipients-names recipient-search active"><ul>';
					for(let i=0; i < arreglo_filtrado.length; i++){
						recipients += '<li>'+
	                        '<div class="form-check">'+
	                            '<input type="checkbox" class="form-check-input users_search_checkbox" value="'+personas_buscar_telefono[personas_buscar_nombre.indexOf(arreglo_filtrado[i])]+'" id="promoters-'+i+'">'+
	                            '<label class="form-check-label" for="promoters-'+i+'">'+arreglo_filtrado[i]+'</label>'+
	                        '</div>'+
	                    '</li>';
					}
				recipients += '</ul></div>';
				$('#container-recipients-names').append(recipients);

			}else{
				$('.list-search').remove();
				$('.recipient-search').remove();
			}
		});

		$(document).on('click','.nav-tabs-info .nav-link-customize', function(event){
			event.preventDefault();
			$('.nav-tabs-info .nav-link-customize').removeClass('active');
			$(this).addClass('active');
		});


		$("#search-messages").keyup(function(){
			var search = $(this).val();

			$.ajax({
				type: 'GET',
				url: base_url+'/tableroEmergencias/SearchMessages',
				headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
				data: ({
					search
				}),
				success: function(response){

					$('.content-messages-list').html('');

					if(response.success==true)
					{
						let to = '';

						if(response.messages.length>0)
						{
							$.each(response.messages, function(index, value){

								to = value.to ? value.to : '';

                                $('.content-messages-list').append(`<div class="message fs-12">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="from font-weight-bold m-0 mr-2">${value.usuario}</p>
                                        <p class="date-time text-right m-0 fs-10">${value.fecha}</p>
                                    </div>
									<p class="fs-12 font-weight-bold m-0"><span class="to">Enviado a:</span> ${to}</p>
									<p class="message-content font-weight-bold m-0">${value.mensaje}</p>
								</div>`);
							});
						}
						else
						{
							$('.content-messages-list').html('<p class="p-3">Sin Resultados</p>');
						}

					}
					else {
						$('.content-messages-list').html('Error al realizar busqueda');
					}
				},
				error: function( jqXhr, textStatus, errorThrown ){
					console.log( errorThrown );
				}
			});
		});
	}

});
