$(window).ready(function(){
	/**
	 * Iniciar mapa
	 */
	if ( document.getElementById('map_emergencia') ) {
		Tablero.init('Cuadrantes');

        Tablero.configPoints = {
            'bomberos': '#136846',
            'centros_acopio': '#f56042',
            'edificios_en_riesgo': '#01759b',
            'hospitales': '#00a1b1',
            'empresas_reconstrucion': '#00b194',
            'ubicacion_c5': '#000000',
            'edificios_en_riesgo': '#01759b',
            'obras': '#799d12',
            'personas_desaparecidas': '#bc42f5',
            'decesos': '#ed82d1',
            'heridos': '#dc6ee6',
            'colapsos': '#f56042',
            'inmuebles_publicos': '#f56042',
            'edificios_gubernamentales': '#f5b342',
            'centro_tabajo': '#e3c456',
            'vivienda_unifamiliar': '#e8886b',
            'vivienda_edificio': '#f56c42',
            'centros_mando': '#ff0000',
            'alcalde_disponible': 'success',
			'coordinador_disponible': 'warning',
		}
		

		// Esta funcion permite actualizar el mapa en tiempo real.
		fwnn = function(){
			// funciona en alcaldias y coordinaciones de seguridad
			datasetsPolygon = ["Alcaldias", "CoordinacionesSeguridad"];
			datasetsPoint = ["refugios", "centros_acopio"]
			/* Para cada elemento de los datasets, almacenar
			en un objeto los elementos mas actuales */
			datasetsPolygon.forEach(function(d) {
			// Objeto vacio
			servInfo = {};
			data = Tablero.polygonsActive[d];
			if (Tablero.map.hasLayer(data)){
				// Lectura del servicio
				servd = Tablero.serviceData(d);
				// Para cada respuesta almacenar la ayuda disponible
				servd.response.forEach(i => servInfo[i.id] = i.disponible_ayuda);
				// Para los poligonos activos.
				if (!("length" in data)){
				// Para cada layer del geojson
				data.eachLayer(function(lyr){
					// Detecta si hay cambios, solo cambia simbologia si hay.
					if (lyr.feature.geometry.disponible_ayuda !== servInfo[lyr.feature.geometry.id]){
					lyr.feature.geometry.disponible_ayuda = servInfo[lyr.feature.geometry.id];
					if (lyr.feature.geometry.disponible_ayuda == true){
						lyr.setStyle({
						fillColor: "#007db3",
						weight: 1,
						opacity: 1,
						color: "#000",
						fillOpacity: '0.4'});
					} else {
						lyr.setStyle({
						fillColor: "#a1a1a1",
						weight: 1,
						opacity: 1,
						color: "#000",
						fillOpacity: '0.4'});
					}
					}
				});
				}
			}	
			});
			
			datasetsPoint.forEach(function(d) {
			// Objeto vacio
			servInfo = {};
			pdata = Tablero.pointsActive[d];
			if (Tablero.map.hasLayer(pdata)){
				// Lectura del servicio
				servp = Tablero.serviceData("Infraestructuras");
				servd = servp.response[d];
				// Para cada respuesta almacenar la ayuda disponible
				servd.forEach(i => servInfo[i.id] = i.instalacion_completa);
				// Para los poligonos activos.
				if (!("length" in pdata)){
				// Para cada layer del geojson
				pdata.eachLayer(function(lyr){
					// Detecta si hay cambios, solo cambia simbologia si hay.
					if (lyr.feature.properties.instalacion_completa !== servInfo[lyr.feature.properties.instalacion_completa]){
					lyr.feature.properties.instalacion_completa = servInfo[lyr.feature.properties.id];
					if (lyr.feature.properties.instalacion_completa == true){
						lyr.setStyle({
						radius: 7,
						fillColor: "#08cc28",
						opacity: 1,
						color: "#08cc28",
						fillOpacity: '0.7',
						weight: .5
						});
					} else {
						lyr.setStyle({
						radius: 7,
						fillColor: "#ff0000",
						opacity: 1,
						color: "#ff000",
						fillOpacity: '0.7',
						weight: .5
						});
					}
					}
				});
				}
			}	
			});
			setTimeout(fwnn, 30000);
		}
		fwnn();

		rtAdd = function(){
			dataTopPoints = ["decesos", "heridos", "personas_desaparecidas"];
			dataTopPoints.forEach(function(p){
				ndata = Tablero.pointsTopActive[p];
				if (Tablero.map.hasLayer(ndata)){
				layers = ndata.getLayers();
				var maxVal = Math.max.apply(Math, layers.map(function(i){return i.feature.properties.id;}));
				$.ajax({
				async: false,
				type: "GET",
				url: base_url + "/tableroEmergencias/nuevos/" + p + "/" + maxVal,
				dataType: 'json',
				success: function(response){
					// console.log(response);
					if(response.length>0){
					console.log(p);
					response.forEach(function(r) {
						var marker = L.circleMarker([r.latitud, r.longitud],
									{
										radius: 10,
										color: "#000000",
										opacity: 1,
										fillColor: "#696969",
										fillOpacity:0.7
									});
						marker.feature = {
						properties: {
							id: r.id
						}
						}				   
						ndata.addLayer(marker);
					});
					}}
				});
			}
			});
			setTimeout(rtAdd, 30000);
		}
	    rtAdd();
	}
    $("#select-reaccion").click()
    $("#alcaldias-representante-filter").click();
    $("#disintegration-quadrants").click();
});

/**
 * Al clic, recibe estatus del switch
 */
$('.section-selected').on('click', function(event) {
	event.preventDefault();
	
	$('.section-selected').removeClass('active');
	var action_switch = $(this).attr('data-section');
	$(this).addClass('active');
	$('.filters-tablero').addClass('d-none');
	$('.filters-'+action_switch).removeClass('d-none');

	if(action_switch == 'reaccion'){
		Tablero.typeInfo = 'reaction';
		Tablero.setInfo();
		Tablero.setPolygonsMapAllActive();
		// Tablero.setPointDisponibles('alcalde_disponible');
		// Tablero.setPointDisponibles('coordinador_disponible');

		$('#switch-attention').removeClass('d-none').addClass('d-flex');
		$('.status-comite').removeClass('d-none');


		$('.comiteInstalado').show();
	}else{
		Tablero.typeInfo = 'preparation';
		Tablero.setInfo();
		Tablero.setPolygonsMapAllActive();

		$('#switch-attention').addClass('d-none').removeClass('d-flex');
		$('.status-comite').addClass('d-none');

		$('.comiteInstalado').hide();
	}
});

/**
 * Al clic, activa o desactiva los filtros de Evaluación
 */
$(document).on("click","input[name=switch-attention]:checkbox",function(){
	var value = $(this).val();

	if(value == '1'){
		$('.filters-costos-humanos').addClass('d-none');
		$('.filters-edificios').removeClass('d-none');
		$(this).val(0);
	}else{
		$('.filters-costos-humanos').removeClass('d-none');
		$('.filters-edificios').addClass('d-none');
		$(this).val(1);
	}
});

/**
 * Activa o desactiva los poligonos
 */
$(document).on("click", "input.input_left_map", function(){
	var active = $(this).attr('data-disintegration');

	if(! $(this).hasClass('active')){
		$(this).addClass('active');
		Tablero.setPolygonsMap(active);
	}else{
		$(this).removeClass('active');
		Tablero.clenPolygonsMap(active);
	}
});

/**
 * Activa o desactiva los filtros del mapa
 */
$(document).on("click", "input.input_rigth_map", function(){
	var active = $(this).attr('data-infrastructure');

	if(! $(this).hasClass('active'))
	{
		$(this).addClass('active');
		Tablero.setPointsMap(active);
	}else
	{
		$(this).removeClass('active');
		Tablero.clenPointsMap(active);
	}
});

/**
 * Activa o desactiva los filtros del mapa
 */
$(document).on("click", ".input_top_map", function(){
	var active = $(this).attr('data-infrastructure');
	var is_coordinadores_presentes =  active == 'coordinadores-presentes';
	var is_alcaldias_representante =  active == 'alcaldias-representante';
	
	var no_interactive_with_map = is_coordinadores_presentes || is_alcaldias_representante;

	if(no_interactive_with_map)
	{
	    if(is_coordinadores_presentes)
            $('#disintegration-coordination').click();
	    if(is_alcaldias_representante)
            $('#disintegration-mayoralties').click();
    }

    if(! $(this).hasClass('active'))
	{
        $(this).addClass('active');
        Tablero.setPointsTopMap(active);
    }
	else
	{
        $(this).removeClass('active');
        if(!no_interactive_with_map)
            Tablero.clenPointsTopMap(active);
    }

});

/**
 * Activa o desactiva acordeon de detalle
 */
$('#acordeonBody').on('change', '.comite_instalado', function(event) {
	let type  = $(this).data('type');
	let id    = $(this).val();
	let value = 0;

	if ($(this).prop('checked')) {
		value = 1;
	}

	if(type)
	{
		$.ajax({
			async: false,
			type: 'POST',
			url: base_url + '/tableroEmergencias/comiteInstalado',
			data: {id, type, value},
			
			success: function(response) {
				console.log(response);
			},
			error: function(jqXhr, textStatus, errorThrown) {
				console.log(errorThrown);
			}
		});

		Tablero.setPolygonsMap(type, true);


		Tablero.clenPolygonsMap(type);
	}
});


$("#acordeonBody").on("click", ".activePoligon", function(event){
	event.preventDefault();

	let type = $(this).parents('tr').find('.comiteInstalado').find('input').data('type');
	let id   = $(this).parents('tr').find('.comiteInstalado').find('input').val();

	$.ajax({
		async: false,
		type: 'POST',
		url: base_url + '/tableroEmergencias/getPolygon',
		data: {type, id},
		
		success: function(response) {


			Tablero.showPolygon(response);
		},
		error: function(jqXhr, textStatus, errorThrown) {
			console.log(errorThrown);
		}
	});

});


/**
 * Activa o desactiva acordeon de detalle
 */
$(document).on('change', '.centro_acopio', function(event) {

	let type  = $(this).data('type');
	let id    = $(this).val();
	let value = 0;

	if ($(this).prop('checked')) {
		value = 1;
	}
	

	if(type)
	{
		$.ajax({
			async: false,
			type: 'POST',
			url: base_url + '/tableroEmergencias/activarInfraestructura',
			data: {id, type, value},
			
			success: function(response) {
				console.log(response);
			},
			error: function(jqXhr, textStatus, errorThrown) {
				console.log(errorThrown);
			}
		});
	}
});


