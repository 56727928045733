/** ** *** ** *** ** *** * **
*** TABLERO DE EMERGENCIAS  *
*** ** *** ** *** ** *** * */

window.Tablero = {};

/**
 * [init description]
 */
Tablero.init = function(type_init) {
	/**
     * Inicializa el mapa
     */
	this.map = L.map('map_emergencia', {
		zoomControl: false,
		dragging: !L.Browser.mobile,
		tap: !L.Browser.mobile,
		attributionControl: false
	}).setView([ 19.369903, -99.148074 ], 11);

	this.lastPolygon = [];

	/**
     * Layers Polygons Actives in map
     */
	this.polygonsActive = {
		Cuadrantes: [],
		CoordinacionesSeguridad: [],
		Alcaldias: [],
		Intervenciones: []
	};

	this.polygonsActiveDom = {
		Cuadrantes: 0,
		CoordinacionesSeguridad: 0,
		Alcaldias: 0,
		Intervenciones: 0
	};

	/**
     * Polygons Save
     */
	this.polygons = {
		Cuadrantes: [],
		CoordinacionesSeguridad: [],
		Alcaldias: [],
		Intervenciones: []
	};

	this.pointsActive = [];

	this.points = [];

	this.configPoints = [];

	this.pointsTopActive = [];

	this.pointsTop = [];

	/**
     * Data Polygon clicked
     */
	this.currentClick = [];

	/**
     * Current Layer Polygon clicked
     */
	this.currentPolygon = 0;

	this.layer = null;

	this.typeInfo = 'preparation';

	this.generateMap();

	// Recipients messages
	this.recipients;

	// CARGA LOS POLIGONOS INDICADOS AL INICIAR EL OBJETO
	this.setPolygonsMap(type_init);
};

/**
 * Generate Mapbox
 */
Tablero.generateMap = function() {
	var map = this.map;
	L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', { maxZoom: 18, minZoom: 10 }).addTo(map);

	setTimeout(function() {
		map.invalidateSize();
	}, 100);

	new L.Control.Zoom({ position: 'bottomleft' }).addTo(map);

	map.scrollWheelZoom.disable();

	/**
	* Buscador
	*/
	var input = document.getElementById('search');

	var bangaloreBounds = new google.maps.LatLngBounds(
		new google.maps.LatLng(19.3435656, -99.2151238),
		new google.maps.LatLng(19.4326077, -99.133208)
	);

	var options = {
		bounds: bangaloreBounds,
		strictBounds: true,
		componentRestrictions: { country: 'mx' }
	};

	var autocomplete = new google.maps.places.Autocomplete(input, options);

	var cssIcon = L.divIcon({
		className: 'fa-icon',
		html: `<i class="fa fa-map-marker fa-3x text-danger"></i>`,
		iconSize: [ 40, 40 ]
	});

	var that = this;
	var markerPrev = null;


	autocomplete.addListener('place_changed', function() {
		var place = autocomplete.getPlace();
		
		var marker = L.marker([place.geometry.location.lat(),place.geometry.location.lng()], { icon: cssIcon })
					.bindPopup(`<p style="display:inline; font-weight: normal;">${place.formatted_address}}</p>`)
					.addTo(that.map);

		markerPrev ? that.map.removeLayer(markerPrev) : ''; 

		markerPrev = marker;
		});

	$("#btn-clear-search").click(function(event){
		event.preventDefault();
	
		$("#search").val('');

		that.map.removeLayer(markerPrev);
	});
};

/**
 * Set Polygonos in Map
 */
Tablero.setPolygonsMap = function(service, refresh = false) {
	this.polygonsActiveDom[service] = 1;

	if (this.polygons[service].length == 0 || refresh == true) {
		var data = this.serviceData(service);
		this.polygons[service] = data;
	}

	this.lastPolygon = [];
	this.polygonsActive[service] = L.geoJSON(this.polygons[service].response, {
		onEachFeature: this.onEachFeature
	}).setStyle(this.styleAlcaldias);
	this.polygonsActive[service].addTo(this.map).bringToFront();
};

Tablero.setPolygonsMapAllActive = function() {
	$.each(this.polygonsActiveDom, function(key, value) {
		if (value == 1) {
			Tablero.clenPolygonsMap(key);
			Tablero.setPolygonsMap(key);
		}
	});
};

/**
 * Destroy Polygons Map
 */
Tablero.clenPolygonsMap = function(type) {
	this.polygonsActiveDom[type] = 0;
	this.map.removeLayer(this.polygonsActive[type]);
};

/**
 * Service data
 */
Tablero.serviceData = function($service) {
	$.ajax({
		async: false,
		type: 'GET',
		url: base_url + '/tableroEmergencias/' + $service,
		dataType: 'json',
		success: function(response) {
			Tablero.lastPolygon = response;
		},
		error: function(jqXhr, textStatus, errorThrown) {
			console.log(errorThrown);
		}
	});

	return this.lastPolygon;
};

/**
 * Define Style Alcaldia
 */
Tablero.styleAlcaldias = function(feature) {
	return {
		fillColor: Tablero.getColorAlcaldias(
			feature.geometry.color,
			feature.geometry.tipo,
			feature.geometry.disponible_ayuda
		),
		weight: 1,
		opacity: 1,
		color: '#000',
		fillOpacity: '0.4'
	};
};

/**
 * Colors Alcaldias
 */
Tablero.getColorAlcaldias = function(color, tipo, visible) {
	if (this.typeInfo == 'reaction' && (tipo == 'alcaldias' || tipo == 'coordinaciones_de_seguridad')) {
		if (visible == 1) {
			return '#007db3';
		} else {
			return '#a1a1a1';
		}
	} else {
		if (color == 'IndianRed') {
			return '#E87273';
		} else if (color == 'SteelBlue') {
			return '#6a79b0';
		} else if (color == 'green') {
			return '#64905c';
		} else if (color == 'yellow') {
			return '#f7c87e';
		} else if (color == 'blue') {
			return '#619fc8';
		}
	}
};

/**
 * Change polygon
 */
Tablero.onEachFeature = function(feature, layer) {
	layer.on({
		click: Tablero.highlightFeature
	});
};

/**
 * Callback clink in pilygon
 */
Tablero.highlightFeature = function(e) {
	var id = this.feature.geometry.id;
	var tipo = this.feature.geometry.tipo;

	var data = Tablero.serviceData('InterseccionPoligono?id=' + id + '&tipo=' + tipo);
	Tablero.lastPolygon = [];
	Tablero.currentClick = data;

	Tablero.setDetails(this.feature.geometry, data);

	Tablero.setInfo();

	Tablero.setRecipients(this.feature.geometry, data);

	Tablero.actionsActivePolygon(e.target);

	$('.info-selected').addClass('active');
	$('.btns-tab').removeClass('hidden');
	$('#detalle').removeClass('hidden');
	$('body,html').stop(true, true).animate(
		{
			scrollTop: $('#detalle').offset().top - 20
		},
		500
	);
};

/**
 * Actiond After click polygon
 */
Tablero.actionsActivePolygon = function(target) {
	if (Tablero.layer != null) {
		Tablero.layer.setStyle({
			weight: 1.5,
			opacity: 1,
			color: '#000',
			fillOpacity: '0.5'
		});

		Tablero.currentPolygon = Tablero.currentPolygon === null ? 0 : Tablero.layer._leaflet_id;
	}

	if (Tablero.currentPolygon != target._leaflet_id) {
		Tablero.layer = target;
		Tablero.layer.setStyle({
			weight: 9,
			color: '#fff',
			fillOpacity: '0.65'
		});

		Tablero.currentPolygon = 0;
	} else {
		Tablero.currentPolygon = null;
	}

	$('body,html').stop(true, true).animate(
		{
			scrollTop: $('#detalle').offset().top - 20
		},
		500
	);
};

/**
 * SET DETAILS POLYGON CLICK
 */
Tablero.setDetails = function(element, data) {
	var type_name = element.title_click;
	$('.title-selected').text(type_name);
	var tipo = element.tipo;

	let preparacion = $('#select-preparacion').hasClass('active');

	$('#acordeonBody').html('');

	if (tipo == 'cuadrantes') {
		$('#acordionDetalle').hide();
	} else {
		$('#acordionDetalle').show();
	}

	if (tipo == 'coordinaciones_de_seguridad') {
		$('#acordionHeader').html(`Cuadrantes de ${type_name.toLowerCase()}  <i class="fa" aria-hidden="true"></i>`);

		data.response.cuadrantes.map((item, index) => {
			$('#acordeonBody').append(`
                                <tr class="border-bottom">
                                    <td><h3 class="font-weight-bold"><a href="#" class="text-decoration-none activePoligon">#${item.id}</a></h3></td>
                                    <td><h3><span class="text-primary font-weight-bold">Jefe de cuadrante:</span> ${item.designado_jdg} / <span class="font-weight-bold">${item.designado_jdg_tel}</span></h3></td>
									<td class="comiteInstalado">
										<input type="hidden" value="${item.id}" class="comite_instalado" id="comiteInstalado${index}" data-type="Cuadrantes">
									</td>
                                </tr>`);
		});
	} else if (tipo == 'alcaldias') {
		$('#acordionHeader').html(
			`Coordinaciones Territoriales en ${type_name.toLowerCase()}  <i class="fa" aria-hidden="true"></i>`
		);

		data.response.coordinaciones_de_seguridad.map((item, index) => {

			let checked = item.disponible_ayuda == true ? 'checked' : '';
			let visible = preparacion==true ? 'display:none' : '';

			$('#acordeonBody').append(`
                                <tr class="border-bottom">
                                    <td><h3 class="font-weight-bold"><a href="#" class="text-decoration-none activePoligon">${item.coor}</a></h3></td>
                                    <td><h3><span class="text-primary font-weight-bold">Encargado de esta coordinación:</span> ${item.designado_jdg} / <span class="font-weight-bold">${item.designado_jdg_tel}</span></h3></td>
									<td class="comiteInstalado" style="${visible}">
										<div class="custom-control custom-switch">
											<input type="checkbox" value="${item.id}" class="custom-control-input comite_instalado" id="comiteInstalado${index}" ${checked} data-type="CoordinacionesSeguridad">
											<label class="custom-control-label" for="comiteInstalado${index}">En Sitio</label>
										</div>
									</td>
                                </tr>`);
		});
	} else if (tipo == 'zonas_intervencion') {
		$('#acordionHeader').html(
			`Alcaldías en la región ${type_name.toLowerCase()}  <i class="fa" aria-hidden="true"></i>`
		);

		data.response.alcaldias.map((item, index) => {

			let checked = item.disponible_ayuda == true ? 'checked' : '';
			let visible = preparacion==true ? 'display:none' : '';

			$('#acordeonBody').append(`
                                  <tr class="border-bottom">
                                      <td><h3 class="font-weight-bold"><a href="#" class="text-decoration-none activePoligon">${item.nomgeo}</a></h3></td>
                                      <td><h3><span class="text-primary font-weight-bold">Alcalde:</span> ${item.alcalde} / <span class="font-weight-bold">${item.alcalde_tel}</span></h3></td>
                                      <td class="comiteInstalado" style="${visible}">
                                        <div class="custom-control custom-switch">
                                          <input type="checkbox" value="${item.id}" class="custom-control-input comite_instalado" id="comiteInstalado${index}" ${checked} data-type="Alcaldias">
                                          <label class="custom-control-label" for="comiteInstalado${index}">En Sitio</label>
                                        </div>
                                      </td>
                                  </tr>`);
		});
	}

	$('.alcalde').addClass('d-none');
	if (
		typeof data.response.alcaldia.alcalde !== 'undefined' &&
		(data.response.alcaldia.alcalde != '' || data.response.alcaldia.alcalde_tel != '') &&
		data.response.tipo != 'cuadrantes'
	) {
		$('.alcalde').removeClass('d-none');
		$('.alcalde .name').text(data.response.alcaldia.alcalde);
		$('.alcalde .tel').text(data.response.alcaldia.alcalde_tel);
	}

	$('.suplente-alcaldia').addClass('d-none');
	if (
		typeof data.response.alcaldia.suplente !== 'undefined' &&
		(data.response.alcaldia.suplente != null && data.response.alcaldia.suplente_tel != '') && (tipo == 'alcaldias' || tipo == 'cuadrantes' || tipo == 'coordinaciones_de_seguridad')
	) {
		$('.suplente-alcaldia').removeClass('d-none');
		$('.suplente-alcaldia .name').text(data.response.alcaldia.suplente);
		$('.suplente-alcaldia .tel').text(data.response.alcaldia.suplente_tel);
	}

	$('.secretaria-alcaldia').addClass('d-none');
	if (
		typeof data.response.alcaldia.secretaria !== 'undefined' &&
		(data.response.alcaldia.secretaria != null && data.response.alcaldia.secretaria_tel != '') && (tipo == 'alcaldias' || tipo == 'cuadrantes' || tipo == 'coordinaciones_de_seguridad')
	) {
		$('.secretaria-alcaldia').removeClass('d-none');
		$('.secretaria-alcaldia .name').text(data.response.alcaldia.secretaria);
		$('.secretaria-alcaldia .tel').text(data.response.alcaldia.secretaria_tel);
	}


	$('.representante-alcaldia').addClass('d-none');
	if (
		typeof data.response.alcaldia.designado !== 'undefined' &&
		(data.response.alcaldia.designado != '' || data.response.alcaldia.designado_tel != '') && (tipo == 'alcaldias' || tipo == 'cuadrantes' || tipo == 'coordinaciones_de_seguridad')
	) {
		$('.representante-alcaldia').removeClass('d-none');
		$('.representante-alcaldia').removeClass('d-none');
		$('.representante-alcaldia .name').text(data.response.alcaldia.designado);
		$('.representante-alcaldia .tel').text(data.response.alcaldia.designado_tel);
	}

	

	$('.encargado-coordinacion').addClass('d-none');
	if (
		typeof data.response.coordinacion.encargado !== 'undefined' &&
		(data.response.coordinacion.encargado != null && data.response.coordinacion.encargado_tel != '') && tipo == 'coordinaciones_de_seguridad'
	) {
		$('.encargado-coordinacion').removeClass('d-none');
		$('.encargado-coordinacion .name').text(data.response.coordinacion.encargado);
		$('.encargado-coordinacion .tel').text(data.response.coordinacion.encargado_tel);
	}

	$('.suplente-coordinacion, .suplente-coordinacion-list').addClass('d-none');
	if (
		typeof element.nombre_suplente !== 'undefined' &&
		(element.nombre_suplente != '' || element.telefono_suplente != '')
	) {
		$('.suplente-coordinacion, .suplente-coordinacion-list').removeClass('d-none');
		$('.suplente-coordinacion .name').text(element.nombre_suplente);
		$('.suplente-coordinacion .tel').text(element.telefono_suplente);
	}

	$('.coordinador_ciudadanas, .suplente-coordinacion-list').addClass('d-none');
	if (
		typeof element.coordinador_ciudadanas !== 'undefined' &&
		(element.coordinador_ciudadanas != '' || element.coordinador_ciudadanas_telefono != '')
	) {
		$('.coordinador_ciudadanas, .suplente-coordinacion-list').removeClass('d-none');
		$('.coordinador_ciudadanas .name').text(element.coordinador_ciudadanas);
		$('.coordinador_ciudadanas .tel').text(element.coordinador_ciudadanas_telefono);
	}

	$('.jefe-cuadrante, .jefe-cuadrante-list').addClass('d-none');
	if (
		typeof data.response.jefe_cuadrante.nombre !== 'undefined' &&
		(data.response.jefe_cuadrante.nombre != '' || data.response.jefe_cuadrante.telefonos != '')
	) {
		$('.jefe-cuadrante, .jefe-cuadrante-list').removeClass('d-none');
		$('.jefe-cuadrante-list .name').text(data.response.jefe_cuadrante.nombre);
		$('.jefe-cuadrante-list .tel').text(data.response.jefe_cuadrante.telefonos);
	}

	$('.coordinador').addClass('d-none');
	if (
		typeof data.response.coordinador.nombre !== 'undefined' &&
		(data.response.coordinador.nombre != '' ||
			data.response.coordinador.telefono != '')
	){

		$('.coordinador').removeClass('d-none');
		$('.coordinador .name').text(data.response.coordinador.nombre);
		$('.coordinador .tel').text(data.response.coordinador.telefono);
	}

	$('.responsable-busqueda, .responsable-busqueda-list').addClass('d-none');
	if (typeof element.institucion_responsable !== 'undefined' && element.institucion_responsable != '') {
		$('.responsable-busqueda, .responsable-busqueda-list').removeClass('d-none');
		$('.responsable-busqueda .name').text(element.institucion_responsable);
	}

	$('.apoyo-busqueda, .apoyo-busqueda-list').addClass('d-none');
	if (typeof element.instituciones_apoyo !== 'undefined' && element.instituciones_apoyo != '') {
		$('.apoyo-busqueda, .apoyo-busqueda-list').removeClass('d-none');
		$('.apoyo-busqueda .name').text(element.instituciones_apoyo);
	}

	$('.participacion, .participacion-list').addClass('d-none');
	if (
		typeof data.response.coordinacion_participacion.nombre !== 'undefined' &&
		(data.response.coordinacion_participacion.nombre != '' ||
			data.response.coordinacion_participacion.telefono != '')
	) {
		$('.participacion, .participacion-list').removeClass('d-none');
		$('.participacion .name').text(data.response.coordinacion_participacion.nombre);
		$('.participacion .tel').text(data.response.coordinacion_participacion.telefono);
	}

	$('.proteccion, .proteccion-list').addClass('d-none');
	if (
		typeof data.response.proteccion_civil.nombre !== 'undefined' &&
		(data.response.proteccion_civil.nombre != '' || data.response.proteccion_civil.telefono != '')
	) {
		$('.proteccion, .proteccion-list').removeClass('d-none');
		$('.proteccion .name').text(data.response.proteccion_civil.nombre.replace(' - -', ''));
		$('.proteccion .tel').text(data.response.proteccion_civil.telefono);
	}
};

Tablero.setRecipients = function(element, data) {
	this.recipients = {
		usuarios_sentika_especializados: data.response.infraestructura.values.voluntarios_sentika_especializados,
		usuarios_sentika_fijos: data.response.infraestructura.values.voluntarios_sentika_fijos,
		usuarios_sentika_moviles: data.response.infraestructura.values.voluntarios_sentika_moviles,
		usuarios_promotores: data.response.infraestructura.values.promotores,
		usuarios_empresas: data.response.infraestructura.values.empresas_reconstrucion,
		usuarios_alcaldes: data.response.infraestructura.values.alcaldes,
		usuario_responsable: {
			nombre: element.contacto_responsable,
			telefono: element.tel_responsable
		}
	};
};

/**
 * SET INFO POLYGON CLICK
 */
Tablero.setInfo = function() {
	var navTabs = '';

	$('#info-tabs').empty();

	$.each(this.currentClick.response, function(key, value) {
		if (value.type === Tablero.typeInfo) {
			navTabs += '<div class="nav-tabs-info">';
			navTabs += '<div class="encabezado">';
			navTabs += '<a data-toggle="collapse" href="#' + key + '" role="button" style="text-decoration:none">';
			navTabs += '<h2 class="titulo-busqueda">' + value.nombre + '</h2>';
			navTabs += '</a>';
			navTabs += '<a data-toggle="collapse" href="#' + key + '" role="button" style="text-decoration:none">';
			navTabs += '<img src="' + base_url + '/images/collapse.svg"></a>';
			navTabs += '</div>';

			navTabs += '<div class="collapse show" id="' + key + '">';
			navTabs +=
				'<div class="nav flex-column nav-pills" id="v-pills-tab1" role="tablist" aria-orientation="vertical">';

			$.each(value.values, function(key, value) {
				if (key != 'promotores') {
					var name = key
						.replace(/_/gi, ' ')
						.replace('ion', 'ión')
						.replace('ion', 'ión')
						.replace('publi', 'públi');
					name = Tablero.maysFirstCharacter(name);
					navTabs += '<a class="nav-link-customize" data-toggle="tab" href="#' + key + '">';
					navTabs += name + ': ' + value.length;
					navTabs += '</a>';
					Tab.template(key, value);
				}
			});
			navTabs += '</div>';
			navTabs += '</div>';
			navTabs += '</div>';
		}
	});

	$('#nav-info').empty().append(navTabs);
};

/**
 * Set Points in Map
 */
Tablero.setPointsMap = function($service) {

	if (this.points.length == 0) {
		var activate = {};
		var data = this.serviceData('Infraestructuras');
		this.points = data.response;

		$.each(this.points, function(key, value) {
			activate[key] = L.featureGroup();
		});

		this.pointsActive = activate;
	}

	var color = '#00a1b1';
	if (this.configPoints[$service] !== 'undefined') color = this.configPoints[$service];

	for (var i = this.points[$service].length - 1; i >= 0; i--) {
		var texto = '';
		if (this.points[$service][i].texto !== 'undefined') texto = this.points[$service][i].texto;

		if ($service == 'centros_mando' || $service == 'centros_acopio' || $service == 'refugios') {
			let instalacion = this.points[$service][i].instalado_correctamente
				? this.points[$service][i].instalado_correctamente
				: this.points[$service][i].instalacion_completa;
			color = instalacion == 1 ? '#08cc28' : '#ff0000';
		}

		if($service=='centros_acopio' || $service=='refugios')
		{
			let popupContent = '';

			if($service=='centros_acopio')
			{
				popupContent = `<p style="display:inline; font-weight: normal;"><i class="fas fa-people-carry text-danger"></i> ${this.points[$service][i].nombre}<br/><strong>${this.points[$service][i].region}</strong></p>`;
			}
			else if($service=='refugios')
			{
				popupContent = `<p style="display:inline; font-weight: normal;"><i class="fas fa-map-marker-alt text-danger"></i> ${this.points[$service][i].calle_y_nu} ${this.points[$service][i].colonia} ${this.points[$service][i].alcaldia} C.P. ${this.points[$service][i].cp} <br/></br><strong>Capacidad: ${this.points[$service][i].capacidad_refugio}</strong><br/><strong>Uso: ${this.points[$service][i].uso_inmueb}</strong></p>`;
			}

			var marker = L.circleMarker([ this.points[$service][i].latitud, this.points[$service][i].longitud ], {
				radius: 7,
				color: color,
				opacity: 1,
				fillColor: color,
				fillOpacity: 0.7,
				weight: 0.5
			});
		    
		    marker.feature = {
			properties: {
			    id: this.points[$service][i].id,
			    instalacion_completa: this.points[$service][i].instalacion_completa
			}
		    };
		    
		    marker.addTo(this.pointsActive[$service]);
		    marker.bindPopup(popupContent);
		    marker.addTo(this.pointsActive[$service]);
		}
		else
		{

			L.circleMarker([ this.points[$service][i].latitud, this.points[$service][i].longitud ], {
				radius: 7,
				color: color,
				opacity: 1,
				fillColor: color,
				fillOpacity: 0.7,
				weight: 0.5,
				properties: {
					id: this.points[$service][i].id,
					instalacion_completa: this.points[$service][i].instalacion_completa
				}
			}).addTo(this.pointsActive[$service]);
			//}).bindPopup('<p style="display:inline; font-weight: normal;">' + this.points[$service][i].nombre + '</p> <br>' + '<p style="display:inline; font-weight: normal;">' + texto + '</p> <br>').addTo(this.pointsActive[$service]);
		}
	}

	this.pointsActive[$service].addTo(this.map);
};

/**
 * Set Points TOP in Map
 */
Tablero.setPointsTopMap = function($service) {

	if (this.pointsTop.length == 0) {
		var activate = {};
		var data = this.serviceData('Danios');

		this.pointsTop = data.response;

		$.each(this.pointsTop, function(key, value) {
			activate[key] = L.featureGroup();
		});

		this.pointsTopActive = activate;
	}

	var color = '#00a1b1';
	var not_interactive_with_map = $service == 'coordinadores-presentes' || $service == 'alcaldias-representante';
	if (!not_interactive_with_map) {
		color = this.configPoints[$service];
		for (var i = this.pointsTop[$service].length - 1; i >= 0; i--) {
			var texto = '';
			if (this.pointsTop[$service][i].texto !== 'undefined') texto = this.pointsTop[$service][i].texto;


			if($service == 'colapsos')
			{
			    L.circleMarker([ this.pointsTop[$service][i].latitud, this.pointsTop[$service][i].longitud ], {
					radius: 7,
					color: color,
					opacity: 1,
					fillColor: color,
					fillOpacity: 0.7,
					weight: 0.5,
				}).addTo(this.pointsTopActive[$service])
				.bindPopup(`<p style="display:inline; font-weight: normal;"><i class="fas fa-map-marker-alt text-danger"></i> ${this.pointsTop[$service][i].calle}  ${this.pointsTop[$service][i].numero}<br/><strong>${this.pointsTop[$service][i].alcaldia}</strong></p>`).addTo(this.pointsTopActive[$service]);
			}
			else if($service == 'riesgo-colapso' || $service == 'explosiones' || $service == 'fugas' || $service == 'personas')
			{
				let prioridad = this.pointsTop[$service][i].prioridad;


	
			if(prioridad == 'Alta')
				{
					color = '#fc7070';
				}
				else if(prioridad == 'Media')
				{
					color = '#fad159';
				}
				else
				{
					color = '#15cdd4';
				}

				

			    L.circleMarker([ this.pointsTop[$service][i].latitud, this.pointsTop[$service][i].longitud ], {
					radius: 7,
					color: color,
					opacity: 1,
					fillColor: color,
					fillOpacity: 0.7,
					weight: 0.5,
				}).addTo(this.pointsTopActive[$service])
				.bindPopup(`
								<h4 style="font-size:15px;font-weight: bold;">${this.pointsTop[$service][i].agrupacion}</h4">
								<br><br>
								<span style="font-size:15px;font-weight: normal;">${this.pointsTop[$service][i].submotivo}</span>
								<br>
								<h4 class="badge text-wrap badge-pill text-left font-weight-bold">DIRECCIÓN: ${this.pointsTop[$service][i].calle} ${this.pointsTop[$service][i].numero}, ${this.pointsTop[$service][i].colonia} ${this.pointsTop[$service][i].codigo_postal}, ${this.pointsTop[$service][i].sector_inicio}</h4>
								<br>
								<h4 class="badge text-wrap badge-pill text-left font-weight-bold">COMENTARIOS: ${this.pointsTop[$service][i].comentarios} </h4>
								<br>
								<h4 class="badge badge-pill text-center font-weight-bold" style="background-color:${color}!important;font-size:13px">${this.pointsTop[$service][i].prioridad}</h4>`)
				.addTo(this.pointsTopActive[$service]);
			}
			else
			{
			    var marker = L.circleMarker([ this.pointsTop[$service][i].latitud, this.pointsTop[$service][i].longitud ], {
				radius: 7,
				color: color,
				opacity: 1,
				fillColor: color,
				fillOpacity: 0.7,
				weight: 0.5
			    });
				
			    marker.feature = {
				properties: {
				    id: this.pointsTop[$service][i].id,
				    instalacion_completa: this.pointsTop[$service][i].instalacion_completa
				}
			    };
			    marker.addTo(this.pointsTopActive[$service]);
				//}).bindPopup('<p style="display:inline; font-weight: normal;">' + this.pointsTop[$service][i].nombre + '</p> <br>' + '<p style="display:inline; font-weight: normal;">' + texto + '</p> <br>').addTo(this.pointsTopActive[$service]);
			}
			
		}

		this.pointsTopActive[$service].addTo(this.map);
	}
};

/**
 * Destroy in Map
 */
Tablero.clenPointsMap = function($service) {
	this.map.removeLayer(this.pointsActive[$service]);
};

/**
 * Destroy in Map
 */
Tablero.clenPointsTopMap = function($service) {
	this.map.removeLayer(this.pointsTopActive[$service]);
};

/**
 * Transform un Mays Frist Character of string
 */
Tablero.maysFirstCharacter = function(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

Tablero.setPointDisponibles = function($service) {
	if (this.points.length == 0) {
		var activate = {};
		var data = this.serviceData('Infraestructuras');
		this.points = data.response;

		$.each(this.points, function(key, value) {
			activate[key] = L.featureGroup();
		});

		this.pointsActive = activate;
	}

	var color = 'success';
	if (this.configPoints[$service] !== 'undefined') color = this.configPoints[$service];

	for (var i = this.points[$service].length - 1; i >= 0; i--) {
		var texto = '';

		var cssIcon = L.divIcon({
			className: 'fa-icon',
			html: `<i class="fa fa-user fa-2x text-${color}"></i>`,
			iconSize: [ 40, 40 ]
		});

		var polygon = new L.Polygon(this.points[$service][i].coordinates);

		var bounds = polygon.getBounds();

		var center = bounds.getCenter();

		//L.marker([center['lng'].toFixed(6), center['lat'].toFixed(6)], {icon: cssIcon}).bindPopup(`<p style="display:inline; font-weight: normal;">${this.points[$service][i].nombre}</p> <br><p style="display:inline; font-weight: normal;">${texto}</p> <br>`).addTo(this.pointsActive[$service]);
		L.marker([ center['lng'].toFixed(6), center['lat'].toFixed(6) ], { icon: cssIcon }).addTo(
			this.pointsActive[$service]
		);
	}

	this.pointsActive[$service].addTo(this.map);
};


Tablero.showPolygon = function(data) {

	let type = data.response.tipo;

	if(this.polygonsActive[type])
	{
		Tablero.clenPolygonsMap(type);
	}

	

	this.polygons[type] = data;

	this.lastPolygon = [];
	this.polygonsActive[type] = L.geoJSON(this.polygons[type].response, {
		onEachFeature: this.onEachFeature
	}).setStyle(this.styleAlcaldias);
	this.polygonsActive[type].addTo(this.map).bringToFront();


	
};
