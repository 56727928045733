$(window).ready(function(){
	window.layer_negro = null;
	var mapboxmap = document.getElementById("map");

	if(mapboxmap){
		// Inicializa mapa
		var map = L.map('map', { zoomControl:true }).setView([19.430556,-99.133030], 11);

		map.scrollWheelZoom.disable();
		//se le asigna la capa de mapbox
		L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
	    	attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
	    	maxZoom: 18,
	    	id: 'mapbox.light',
	    	accessToken: 'pk.eyJ1IjoibWFyaW9lZ2IiLCJhIjoiY2p4NmV0ZG1qMDAycjN5bW53ZDkwejkwNCJ9.gDBcIFhpaSDlqeXIDpH75A'
		}).addTo(map);

		//se obtienen los poligonos a pintar y se pintan
		var source = $.ajax({
			type: 'GET',
			url: base_url+'/functions_map/getSource'
			}).fail(function(err,err_2,err_3){
				console.log(err);
				console.log(err_2);
				console.log(err_3);
			}).done(function(response){
				source = null;
				if(response.sucess){
					source = response;
				}
				pintarPoligonos(source);
		});

		function pintarPoligonos(source){
			if(source!==null){
				var featureGroup = L.layerGroup();
				for (var key in source) {
					if(typeof(source[key].polygon)!=="undefined"){
						var myLines = [{
						    "type":source[key].type,
						    "coordinates": JSON.parse(source[key].polygon)
						}];

						var color = "#dc0000";
						var estatusColonia = 'Vacío';
						var nombre = source[key].nombre;
						var cp = source[key].cp;

						switch(source[key].color) {
						  case "rojo":
						    color = "#dc0000";
						    estatusColonia = 'Vacío';
						    break;
						  case "amarillo":
						    color = "#ffd60b";
						    estatusColonia = 'Incompleto';
						    break;
						  case "verde":
						    color = "#2ec409";
						    estatusColonia = 'Completo';
						    break;
						  case "gris":
						    color = "#9e9e9e";
						    estatusColonia = '';
						    break;
						}

						var myStyle = {
						    "fillColor": color,
						    "weight": 1,
						    "fillOpacity": 0.4,
						    "color": "#FFFFFF",
						    "opacity": 0.8
						};
						//var polygon = JSON.parse(source[key].polygon);
						//console.log(polygon);
		  				var polygon = L.geoJSON(myLines, {
		    				style: myStyle,
						});

		  				var contenidoPopUp = getContenidoPopup(color,estatusColonia,nombre,cp);

		  				var popup =  L.popup({closeButton:false})
		  					.setContent(contenidoPopUp);

						polygon.bindPopup(popup);

						polygon.on('mouseover', function (e) {this.openPopup();});
						polygon.on('mouseout', function (e) {this.closePopup();});
						polygon.on('click', function (e) {
							clickColonia(e);
						});
						polygon.addTo(featureGroup);

					}
				}
				featureGroup.addTo(map);
			}
		}

		function clickColonia(poligono) {
			var contenido_popup = poligono.target._popup._content;
			var regex = /:(\s\d+)/g;
			var array_results = regex.exec(contenido_popup);
			var cp = array_results[1].trim();
			sessionStorage.setItem("cp", cp);

			$.get(base_url+'/functions_map/getDatosColoniaByCp?cp='+ cp, function(data, textStatus, xhr) {
				if(textStatus === 'success'){
						if(window.layer_negro!==null){
							map.removeLayer(window.layer_negro);
						}
						var geometry = poligono.target.toGeoJSON().features[0].geometry;
						var cordenadas = geometry.coordinates;
						var split = data.colonia_alcaldia.split(',');
						var colonia_f = split[0];
						$('#resultados-colonia').empty();
						$('#colonia').val(colonia_f);
						$('input#codigo_postal').val(cp);
						// $('#formulario-registro').removeClass('d-none');
						$('#cuantas-personas').removeClass('d-none');
						$('.mostrar_ocultar').removeClass('d-none');
						$('#definir-horario').removeClass('d-none');
						$('#aviso-de-privacidad').removeClass('d-none');
						$('#nombre-colonia').text('Colonia ' + data.colonia_alcaldia);
						setBordeNegro(geometry,data.colonia_alcaldia,cp,data.status);
						setFitMap(cordenadas);
						cargarMapa(cp);
						document.getElementById('cuantas-personas').scrollIntoView();
				}
				else {
					alert('Error en la carga de nombre de colonia');
				}
			});
		}

	}

	/**********************************para activar el seleccionado en horarios************************************/
	$('.schedule > div.schedule__item').on('change', function() {
		var name = $(this).find('input').attr('name');
		//console.log(name);
		$('.'+name).removeClass('active');
		$(this).closest('.schedule__item').addClass('active');
	});

	$('button:not(.btn-filter)').on('click', function() {
		$(this).toggleClass('active');
	});

	/****************************Para el funcionamiento del autocomplete de colonias****************************/

	//funcion que checa si a pasado un minuto y si es asi busca el termino ingresado
	$(document).on('input', '#colonia', function() {
		var search_term = $(this).val();
		var last_input_time = sessionStorage.getItem("last_input_time");
		if(last_input_time==null){
			sessionStorage.setItem("last_input_time", setTimeout(function(){
				buscarTermino(search_term);
			},1000));
		}else{
			clearTimeout(last_input_time);
			sessionStorage.setItem("last_input_time", setTimeout(function(){
				if(search_term==""){
					$('#resultados-colonia').empty();
				}else{
					buscarTermino(search_term);
				}
			},1000));
		}
	});

	function buscarTermino(search_term){
		$.get(base_url+'/functions_map/getColoniasByTerm?termino='+search_term, function(data, textStatus, xhr) {

			if(textStatus === 'success')
			{
				let tamanio_arreglo = data.length;

				if(tamanio_arreglo>0){
					tamanio_arreglo = tamanio_arreglo==1?"1 resultado":tamanio_arreglo+" resultados";
					$('#resultados-colonia').empty();
					$.each(data, function( index, value ) {
						$('#resultados-colonia').append('<li class="seleccion-colonia" data-cp="' + value.cp + '" data-cartodb-id="' + value.cartodb_id + '">' + value.colonia_alcaldia + '</li>');
					});
					crearMensaje(false,"Busqueda exitosa","Se han encontrado "+tamanio_arreglo+" con la palabra <b>'"+search_term+"'</b>");
				}else{
			        crearMensaje(true,"Sin resultados","No se encontraron resultados con la palabra <b>'"+search_term+"'</b>");
				}

				$('#resultados-colonia').empty();
				$.each(data, function( index, value ) {
					$('#resultados-colonia').append('<li class="seleccion-colonia" data-cp="' + value.cp + '" data-cartodb-id="' + value.cartodb_id + '">' + value.colonia_alcaldia + '</li>');
				});
			}
			else {
				crearMensaje(true,"Sin resultados","Error en la carga de datos");
			}
		});

	}

	$(document).on('click', '.seleccion-colonia', function() {
		var cp = $(this).attr('data-cp');
		sessionStorage.setItem("cp", cp);
		//var cartodb_id = $(this).attr('cartodb-id');
		var colonia = $(this).text();
		var ajax_source = $.ajax({
		type: 'GET',
		url: base_url+'/functions_map/getGeometryByCp',
		data: {cp:cp},
		success: function(data){
			if(window.layer_negro!==null){
				map.removeLayer(window.layer_negro);
			}
			var geometry = data.geometry;
			var status = data.status;
			var split = colonia.split(',');
			var colonia_f = split[0];
			$('#resultados-colonia').empty();
			$('#colonia').val(colonia_f);
			if(data.sucess && geometry !=""){
				geometry.coordinates = JSON.parse(geometry.coordinates);
				let cordenadas = geometry.coordinates;
				// $('#formulario-registro').removeClass('d-none');
				$('#cuantas-personas').removeClass('d-none');
				$('.mostrar_ocultar').removeClass('d-none');
				$('#definir-horario').removeClass('d-none');
				$('#aviso-de-privacidad').removeClass('d-none');
				$('#nombre-colonia').text('Colonia ' + colonia);
				setBordeNegro(geometry,colonia,cp,status);
				setFitMap(cordenadas);
				cargarMapa(cp);
				document.getElementById('response').scrollIntoView();
			}else{
				// $('#formulario-registro').addClass('d-none');
				$('#cuantas-personas').addClass('d-none');
				$('.mostrar_ocultar').addClass('d-none');
				$('#definir-horario').addClass('d-none');
				$('#aviso-de-privacidad').addClass('d-none');
				cleanSlots();
				crearMensaje(true,"Sin resultados","Error en la carga de datos");
			}
		},
		error: function( jqXhr, textStatus, errorThrown ){
			console.log( errorThrown );
		}

		});
	});

	$("#searchButton").click(function(){

	});

	/**************************************************funciones generales************************************/
	function cleanSlots() {
		$('.slot-dia label').parent().removeClass( 'incomplete' );
		$('.slot-dia label').parent().removeClass( 'full' );
		$('.slot-dia label').parent().addClass('empty');
		$('.slot-dia label').html('<span>0 Voluntario(s)</span>');
	}

	function setBordeNegro ( geometry,nombre,cp,status ) {
		var contenidoPopUp = getContenidoPopup(null,status,nombre,cp);
		var popup =  L.popup({closeButton:false})
				.setContent(contenidoPopUp);

	    let bordeNegro = L.geoJson([geometry], {
	        style: {
	            color: "#000",
	            weight: 2
	        }
	    });

	    bordeNegro.bindPopup(popup);
		bordeNegro.on('mouseover', function (e) {this.openPopup();});
		bordeNegro.on('mouseout', function (e) {this.closePopup();});

		map.addLayer(bordeNegro);
		window.layer_negro = bordeNegro;
	}

	function setFitMap(cordenadas){
		var leaflet_polygon = L.polygon(cordenadas);
		var bounds_polygon = leaflet_polygon.getBounds();
		var bounds_array = [];
		for (bound in bounds_polygon){
			let tipo = typeof(bounds_polygon[bound]);
			if(tipo !== "function"){
				let lat = bounds_polygon[bound].lat;
				let lng = bounds_polygon[bound].lng;
				bounds_array.push([lng,lat]);
			}
		}
		map.fitBounds(bounds_array);
	}

	function cargarMapa( cp, setValues ) {
		setValues = typeof setValues !== 'undefined' ?  setValues : false;

		cleanSlots();
		$('.label-cp-response span').html('Sin');

	  	// Consultas: nombres de personas registradas en ese CP y disponibilidad de slots
		$.ajax({
			type: 'GET',
			url: base_url+'/functions_map/getPersonasByCp',
			data: ({cp: cp}),
			success: function(data){
				if(data.sucess){
					$('#list-result').empty();
					var cuantos = 0;
					$.each(data, function( index, value ) {
						if(index != 'sucess'){
							$('#list-result').append('<p class="person"><img src="images/icon-voluntario.svg" alt="Voluntario">'+ value +'</p>');
							++cuantos;
						}
					});
					$('.label-cp-response span').text(cuantos);
				}else{
					$('#list-result').empty();
					// $('#list-result').append('<br><span class="person">No hay personas registradas</span>');
				}
			},
			error: function( jqXhr, textStatus, errorThrown ){
				console.log( errorThrown );
			}
		});

		$.ajax({
			type: 'GET',
			url: base_url+'/functions_map/getDisponibilidadByCp',
			data: ({cp: cp}),
			success: function(data){
				if(data.sucess){
					//morning

					$("label[for='ayuda_lunes_manana']")
					.html(data.lunes_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.lunes_manana == 0){
						$("label[for='ayuda_lunes_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_lunes_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_manana']").parent().removeClass( "full" );
					}
					if(data.lunes_manana >= 1 && data.lunes_manana < 5){
						$("label[for='ayuda_lunes_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_lunes_manana']").parent().removeClass( "full" );
					}
					if(data.lunes_manana >= 5){
						$("label[for='ayuda_lunes_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_manana']").parent().addClass( "full" );
					}

					$("label[for='ayuda_martes_manana']")
					.html(data.martes_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.martes_manana == 0){
						$("label[for='ayuda_martes_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_martes_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_manana']").parent().removeClass( "full" );
					}
					if(data.martes_manana >= 1 && data.martes_manana < 5){
						$("label[for='ayuda_martes_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_martes_manana']").parent().removeClass( "full" );
					}
					if(data.martes_manana >= 5){
						$("label[for='ayuda_martes_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_manana']").parent().addClass( "full" );
					}

					$("label[for='ayuda_miercoles_manana']")
					.html(data.miercoles_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.miercoles_manana == 0){
						$("label[for='ayuda_miercoles_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_miercoles_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_manana']").parent().removeClass( "full" );
					}
					if(data.miercoles_manana >= 1 && data.miercoles_manana < 5){
						$("label[for='ayuda_miercoles_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_miercoles_manana']").parent().removeClass( "full" );
					}
					if(data.miercoles_manana >= 5){
						$("label[for='ayuda_miercoles_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_manana']").parent().addClass( "full" );
					}

					$("label[for='ayuda_jueves_manana']")
					.html(data.jueves_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.jueves_manana == 0){
						$("label[for='ayuda_jueves_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_jueves_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_manana']").parent().removeClass( "full" );
					}
					if(data.jueves_manana >= 1 && data.jueves_manana < 5){
						$("label[for='ayuda_jueves_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_jueves_manana']").parent().removeClass( "full" );
					}
					if(data.jueves_manana >= 5){
						$("label[for='ayuda_jueves_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_manana']").parent().addClass( "full" );
					}

					$("label[for='ayuda_viernes_manana']")
					.html(data.viernes_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.viernes_manana == 0){
						$("label[for='ayuda_viernes_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_viernes_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_manana']").parent().removeClass( "full" );
					}
					if(data.viernes_manana >= 1 && data.viernes_manana < 5){
						$("label[for='ayuda_viernes_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_viernes_manana']").parent().removeClass( "full" );
					}
					if(data.viernes_manana >= 5){
						$("label[for='ayuda_viernes_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_manana']").parent().addClass( "full" );
					}

					$("label[for='ayuda_sabado_manana']")
					.html(data.sabado_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.sabado_manana == 0){
						$("label[for='ayuda_sabado_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_sabado_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_manana']").parent().removeClass( "full" );
					}
					if(data.sabado_manana >= 1 && data.sabado_manana < 5){
						$("label[for='ayuda_sabado_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_sabado_manana']").parent().removeClass( "full" );
					}
					if(data.sabado_manana >= 5){
						$("label[for='ayuda_sabado_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_manana']").parent().addClass( "full" );
					}

					$("label[for='ayuda_domingo_manana']")
					.html(data.domingo_manana+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_manana" value="morning" autocomplete="off">' );

					//Colors range
					if(data.domingo_manana == 0){
						$("label[for='ayuda_domingo_manana']").parent().addClass( "empty" );
						$("label[for='ayuda_domingo_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_manana']").parent().removeClass( "full" );
					}
					if(data.domingo_manana >= 1 && data.domingo_manana < 5){
						$("label[for='ayuda_domingo_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_manana']").parent().addClass( "incomplete" );
						$("label[for='ayuda_domingo_manana']").parent().removeClass( "full" );
					}
					if(data.domingo_manana >= 5){
						$("label[for='ayuda_domingo_manana']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_manana']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_manana']").parent().addClass( "full" );
					}

					//afternoon
					$("label[for='ayuda_lunes_tarde']")
					.html(data.lunes_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.lunes_tarde == 0){
						$("label[for='ayuda_lunes_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_lunes_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_tarde']").parent().removeClass( "full" );
					}
					if(data.lunes_tarde >= 1 && data.lunes_tarde < 5){
						$("label[for='ayuda_lunes_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_lunes_tarde']").parent().removeClass( "full" );
					}
					if(data.lunes_tarde >= 5){
						$("label[for='ayuda_lunes_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_tarde']").parent().addClass( "full" );
					}

					$("label[for='ayuda_martes_tarde']")
					.html(data.martes_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.martes_tarde == 0){
						$("label[for='ayuda_martes_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_martes_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_tarde']").parent().removeClass( "full" );

					}
					if(data.martes_tarde >= 1 && data.martes_tarde < 5){
						$("label[for='ayuda_martes_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_martes_tarde']").parent().removeClass( "full" );
					}
					if(data.martes_tarde >= 5){
						$("label[for='ayuda_martes_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_tarde']").parent().addClass( "full" );
					}

					$("label[for='ayuda_miercoles_tarde']")
					.html(data.miercoles_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.miercoles_tarde == 0){
						$("label[for='ayuda_miercoles_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "full" );
					}
					if(data.miercoles_tarde >= 1 && data.miercoles_tarde < 5){
						$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "full" );
					}
					if(data.miercoles_tarde >= 5){
						$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_tarde']").parent().addClass( "full" );
					}

					$("label[for='ayuda_jueves_tarde']")
					.html(data.jueves_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.jueves_tarde == 0){
						$("label[for='ayuda_jueves_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_jueves_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_tarde']").parent().removeClass( "full" );
					}
					if(data.jueves_tarde >= 1 && data.jueves_tarde < 5){
						$("label[for='ayuda_jueves_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_jueves_tarde']").parent().removeClass( "full" );
					}
					if(data.jueves_tarde >= 5){
						$("label[for='ayuda_jueves_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_tarde']").parent().addClass( "full" );
					}

					$("label[for='ayuda_viernes_tarde']")
					.html(data.viernes_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.viernes_tarde == 0){
						$("label[for='ayuda_viernes_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_viernes_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_tarde']").parent().removeClass( "full" );
					}
					if(data.viernes_tarde >= 1 && data.viernes_tarde < 5){
						$("label[for='ayuda_viernes_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_viernes_tarde']").parent().removeClass( "full" );
					}
					if(data.viernes_tarde >= 5){
						$("label[for='ayuda_viernes_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_tarde']").parent().addClass( "full" );
					}

					$("label[for='ayuda_sabado_tarde']")
					.html(data.sabado_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.sabado_tarde == 0){
						$("label[for='ayuda_sabado_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_sabado_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_tarde']").parent().removeClass( "full" );
					}
					if(data.sabado_tarde >= 1 && data.sabado_tarde < 5){
						$("label[for='ayuda_sabado_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_sabado_tarde']").parent().removeClass( "full" );
					}
					if(data.sabado_tarde >= 5){
						$("label[for='ayuda_sabado_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_tarde']").parent().addClass( "full" );
					}

					$("label[for='ayuda_domingo_tarde']")
					.html(data.domingo_tarde+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_tarde" value="after" autocomplete="off">' );

					//Colors range
					if(data.domingo_tarde == 0){
						$("label[for='ayuda_domingo_tarde']").parent().addClass( "empty" );
						$("label[for='ayuda_domingo_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_tarde']").parent().removeClass( "full" );
					}
					if(data.domingo_tarde >= 1 && data.domingo_tarde < 5){
						$("label[for='ayuda_domingo_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_tarde']").parent().addClass( "incomplete" );
						$("label[for='ayuda_domingo_tarde']").parent().removeClass( "full" );
					}
					if(data.domingo_tarde >= 5){
						$("label[for='ayuda_domingo_tarde']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_tarde']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_tarde']").parent().addClass( "full" );
					}

					//night
					$("label[for='ayuda_lunes_noche']")
					.html(data.lunes_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.lunes_noche == 0){
						$("label[for='ayuda_lunes_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_lunes_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_noche']").parent().removeClass( "full" );
					}
					if(data.lunes_noche >= 1 && data.lunes_noche < 5){
						$("label[for='ayuda_lunes_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_lunes_noche']").parent().removeClass( "full" );
					}
					if(data.lunes_noche >= 5){
						$("label[for='ayuda_lunes_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_noche']").parent().addClass( "full" );
					}

					$("label[for='ayuda_martes_noche']")
					.html(data.martes_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.martes_noche == 0){
						$("label[for='ayuda_martes_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_martes_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_noche']").parent().removeClass( "full" );
					}
					if(data.martes_noche >= 1 && data.martes_noche < 5){
						$("label[for='ayuda_martes_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_martes_noche']").parent().removeClass( "full" );
					}
					if(data.martes_noche >= 5){
						$("label[for='ayuda_martes_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_noche']").parent().addClass( "full" );
					}

					$("label[for='ayuda_miercoles_noche']")
					.html(data.miercoles_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.miercoles_noche == 0){
						$("label[for='ayuda_miercoles_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_miercoles_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_noche']").parent().removeClass( "full" );
					}
					if(data.miercoles_noche >= 1 && data.miercoles_noche < 5){
						$("label[for='ayuda_miercoles_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_miercoles_noche']").parent().removeClass( "full" );
					}
					if(data.miercoles_noche >= 5){
						$("label[for='ayuda_miercoles_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_noche']").parent().addClass( "full" );
					}

					$("label[for='ayuda_jueves_noche']")
					.html(data.jueves_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.jueves_noche == 0){
						$("label[for='ayuda_jueves_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_jueves_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_noche']").parent().removeClass( "full" );
					}
					if(data.jueves_noche >= 1 && data.jueves_noche < 5){
						$("label[for='ayuda_jueves_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_jueves_noche']").parent().removeClass( "full" );
					}
					if(data.jueves_noche >= 5){
						$("label[for='ayuda_jueves_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_noche']").parent().addClass( "full" );
					}

					$("label[for='ayuda_viernes_noche']")
					.html(data.viernes_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.viernes_noche == 0){
						$("label[for='ayuda_viernes_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_viernes_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_noche']").parent().removeClass( "full" );
					}
					if(data.viernes_noche >= 1 && data.viernes_noche < 5){
						$("label[for='ayuda_viernes_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_viernes_noche']").parent().removeClass( "full" );
					}
					if(data.viernes_noche >= 5){
						$("label[for='ayuda_viernes_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_noche']").parent().addClass( "full" );
					}

					$("label[for='ayuda_sabado_noche']")
					.html(data.sabado_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.sabado_noche == 0){
						$("label[for='ayuda_sabado_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_sabado_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_noche']").parent().removeClass( "full" );
					}
					if(data.sabado_noche >= 1 && data.sabado_noche < 5){
						$("label[for='ayuda_sabado_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_sabado_noche']").parent().removeClass( "full" );
					}
					if(data.sabado_noche >= 5){
						$("label[for='ayuda_sabado_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_noche']").parent().addClass( "full" );
					}

					$("label[for='ayuda_domingo_noche']")
					.html(data.domingo_noche+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_noche" value="night" autocomplete="off">' );

					//Colors range
					if(data.domingo_noche == 0){
						$("label[for='ayuda_domingo_noche']").parent().addClass( "empty" );
						$("label[for='ayuda_domingo_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_noche']").parent().removeClass( "full" );
					}
					if(data.domingo_noche >= 1 && data.domingo_noche < 5){
						$("label[for='ayuda_domingo_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_noche']").parent().addClass( "incomplete" );
						$("label[for='ayuda_domingo_noche']").parent().removeClass( "full" );
					}
					if(data.domingo_noche >= 5){
						$("label[for='ayuda_domingo_noche']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_noche']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_noche']").parent().addClass( "full" );
					}

					//dawning
					$("label[for='ayuda_lunes_madrugada']")
					.html(data.lunes_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.lunes_madrugada == 0){
						$("label[for='ayuda_lunes_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "full" );
					}
					if(data.lunes_madrugada >= 1 && data.lunes_madrugada < 5){
						$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "full" );
					}
					if(data.lunes_madrugada >= 5){
						$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_lunes_madrugada']").parent().addClass( "full" );
					}

					$("label[for='ayuda_martes_madrugada']")
					.html(data.martes_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.martes_madrugada == 0){
						$("label[for='ayuda_martes_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_martes_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_madrugada']").parent().removeClass( "full" );
					}
					if(data.martes_madrugada >= 1 && data.martes_madrugada < 5){
						$("label[for='ayuda_martes_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_martes_madrugada']").parent().removeClass( "full" );
					}
					if(data.martes_madrugada >= 5){
						$("label[for='ayuda_martes_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_martes_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_martes_madrugada']").parent().addClass( "full" );
					}

					$("label[for='ayuda_miercoles_madrugada']")
					.html(data.miercoles_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.miercoles_madrugada == 0){
						$("label[for='ayuda_miercoles_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "full" );
					}
					if(data.miercoles_madrugada >= 1 && data.miercoles_madrugada < 5){
						$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "full" );
					}
					if(data.miercoles_madrugada >= 5){
						$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_miercoles_madrugada']").parent().addClass( "full" );
					}

					$("label[for='ayuda_jueves_madrugada']")
					.html(data.jueves_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.jueves_madrugada == 0){
						$("label[for='ayuda_jueves_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "full" );
					}
					if(data.jueves_madrugada >= 1 && data.jueves_madrugada < 5){
						$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "full" );
					}
					if(data.jueves_madrugada >= 5){
						$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_jueves_madrugada']").parent().addClass( "full" );
					}

					$("label[for='ayuda_viernes_madrugada']")
					.html(data.viernes_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.viernes_madrugada == 0){
						$("label[for='ayuda_viernes_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "full" );
					}
					if(data.viernes_madrugada >= 1 && data.viernes_madrugada < 5){
						$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "full" );
					}
					if(data.viernes_madrugada >= 5){
						$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_viernes_madrugada']").parent().addClass( "full" );
					}

					$("label[for='ayuda_sabado_madrugada']")
					.html(data.sabado_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.sabado_madrugada == 0){
						$("label[for='ayuda_sabado_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "full" );
					}
					if(data.sabado_madrugada >= 1 && data.sabado_madrugada < 5){
						$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "full" );
					}
					if(data.sabado_madrugada >= 5){
						$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_sabado_madrugada']").parent().addClass( "full" );
					}

					$("label[for='ayuda_domingo_madrugada']")
					.html(data.domingo_madrugada+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_madrugada" value="dawn" autocomplete="off">' );

					//Colors range
					if(data.domingo_madrugada == 0){
						$("label[for='ayuda_domingo_madrugada']").parent().addClass( "empty" );
						$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "full" );
					}
					if(data.domingo_madrugada >= 1 && data.domingo_madrugada < 5){
						$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_madrugada']").parent().addClass( "incomplete" );
						$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "full" );
					}
					if(data.domingo_madrugada >= 5){
						$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "empty" );
						$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "incomplete" );
						$("label[for='ayuda_domingo_madrugada']").parent().addClass( "full" );
					}
				}else{
					/*-------------------------------------------Mañana---------------------------------------*/
					//lunes
					$("label[for='ayuda_lunes_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_lunes_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_lunes_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_lunes_manana']").parent().removeClass( "full" );

					//martes
					$("label[for='ayuda_martes_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_martes_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_martes_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_martes_manana']").parent().removeClass( "full" );

					//miercoles
					$("label[for='ayuda_miercoles_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_miercoles_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_miercoles_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_miercoles_manana']").parent().removeClass( "full" );

					//jueves
					$("label[for='ayuda_jueves_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_jueves_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_jueves_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_jueves_manana']").parent().removeClass( "full" );

					//viernes
					$("label[for='ayuda_viernes_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_viernes_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_viernes_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_viernes_manana']").parent().removeClass( "full" );

					//sabado
					$("label[for='ayuda_sabado_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_sabado_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_sabado_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_sabado_manana']").parent().removeClass( "full" );

					//domingo
					$("label[for='ayuda_domingo_manana']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_manana" value="morning" autocomplete="off">' );
					$("label[for='ayuda_domingo_manana']").parent().addClass( "empty" );
					$("label[for='ayuda_domingo_manana']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_domingo_manana']").parent().removeClass( "full" );

					/*-------------------------------------------Tarde---------------------------------------*/
					//lunes
					$("label[for='ayuda_lunes_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_lunes_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_lunes_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_lunes_tarde']").parent().removeClass( "full" );

					//martes
					$("label[for='ayuda_martes_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_martes_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_martes_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_martes_tarde']").parent().removeClass( "full" );


					//miercoles
					$("label[for='ayuda_miercoles_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_miercoles_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_miercoles_tarde']").parent().removeClass( "full" );

					//jueves
					$("label[for='ayuda_jueves_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_jueves_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_jueves_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_jueves_tarde']").parent().removeClass( "full" );

					//viernes
					$("label[for='ayuda_viernes_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_viernes_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_viernes_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_viernes_tarde']").parent().removeClass( "full" );

					//sabado
					$("label[for='ayuda_sabado_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_sabado_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_sabado_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_sabado_tarde']").parent().removeClass( "full" );

					//domingo
					$("label[for='ayuda_domingo_tarde']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_tarde" value="after" autocomplete="off">' );
					$("label[for='ayuda_domingo_tarde']").parent().addClass( "empty" );
					$("label[for='ayuda_domingo_tarde']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_domingo_tarde']").parent().removeClass( "full" );

					/*-------------------------------------------Noche---------------------------------------*/
					//lunes
					$("label[for='ayuda_lunes_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_lunes_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_lunes_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_lunes_noche']").parent().removeClass( "full" );

					//martes
					$("label[for='ayuda_martes_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_martes_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_martes_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_martes_noche']").parent().removeClass( "full" );

					//miercoles
					$("label[for='ayuda_miercoles_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_miercoles_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_miercoles_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_miercoles_noche']").parent().removeClass( "full" );

					//jueves
					$("label[for='ayuda_jueves_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_jueves_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_jueves_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_jueves_noche']").parent().removeClass( "full" );

					//viernes
					$("label[for='ayuda_viernes_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_viernes_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_viernes_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_viernes_noche']").parent().removeClass( "full" );

					//sabado
					$("label[for='ayuda_sabado_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_sabado_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_sabado_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_sabado_noche']").parent().removeClass( "full" );

					//domingo
					$("label[for='ayuda_domingo_noche']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_noche" value="night" autocomplete="off">' );
					$("label[for='ayuda_domingo_noche']").parent().addClass( "empty" );
					$("label[for='ayuda_domingo_noche']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_domingo_noche']").parent().removeClass( "full" );

					/*-------------------------------------------Madrugada---------------------------------------*/
					//lunes
					$("label[for='ayuda_lunes_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_lunes" id="ayuda_lunes_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_lunes_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_lunes_madrugada']").parent().removeClass( "full" );

					//Martes
					$("label[for='ayuda_martes_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_martes" id="ayuda_martes_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_martes_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_martes_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_martes_madrugada']").parent().removeClass( "full" );

					//Miercoles
					$("label[for='ayuda_miercoles_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_miercoles" id="ayuda_miercoles_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_miercoles_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_miercoles_madrugada']").parent().removeClass( "full" );

					//Jueves
					$("label[for='ayuda_jueves_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_jueves" id="ayuda_jueves_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_jueves_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_jueves_madrugada']").parent().removeClass( "full" );

					//Viernes
					$("label[for='ayuda_viernes_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_viernes" id="ayuda_viernes_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_viernes_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_viernes_madrugada']").parent().removeClass( "full" );

					//Sabado
					$("label[for='ayuda_sabado_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_sabado" id="ayuda_sabado_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_sabado_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_sabado_madrugada']").parent().removeClass( "full" );

					//Domingo
					$("label[for='ayuda_domingo_madrugada']")
					.html(0+' <span>Voluntario(s)</span>')
					.append( '<input type="radio" name="ayuda_domingo" id="ayuda_domingo_madrugada" value="dawn" autocomplete="off">' );
					$("label[for='ayuda_domingo_madrugada']").parent().addClass( "empty" );
					$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "incomplete" );
					$("label[for='ayuda_domingo_madrugada']").parent().removeClass( "full" );
				}

				var n_empty = $('.empty').length;
				var n_incomplete = $('.incomplete').length;
				var n_full = ($('.full').length)-1;

				// Resta número de turnos faltantes e imprime en maqueta
				$('.numero-de-turnos-faltantes span').text( 28 - n_full );

			},
			error: function( jqXhr, textStatus, errorThrown ){
				console.log( errorThrown );
			}
		});
	}

	function getContenidoPopup(color,estatusColonia,nombre,cp) {
		let content = '<div class="widget">';

	  	content += '<h4>' + nombre + '</h4>';
		content += '<p>CP: ' + cp + ' | ' + estatusColonia + ' </p>';
		content += '</div>';

		return content;
	}

	$('#codigo_postal').change(function(){
		var codigo_postal = $('#codigo_postal').val();
		var cp_session = sessionStorage.getItem("cp");
		if(codigo_postal !== cp_session){
			$('#codigo_postal').val(cp_session);
		}else{
			$('#codigo_postal').val(codigo_postal);
		}
	} );

	/**************************************************submit************************************/

	$("#submit_voluntario").click(function(){
		var codigo_postal = $('#codigo_postal').val();
		var cp_session = sessionStorage.getItem("cp");
		var is_especializado = (window.location.href===base_url+"/Especializado");
		if(codigo_postal !== cp_session){
			$('#codigo_postal').val(cp_session);
		}else{
			$('#codigo_postal').val(codigo_postal);
		}
		var btn_especialidad_precionado = true;

		if(is_especializado){
			var botones_especialidad = $(".btn_especialidad");
			var id_input_seleccionado ="";
			btn_especialidad_precionado = false;

			botones_especialidad.each(function(indice,boton){
				var hijos = boton.firstElementChild.children;
				var hijos_tam = hijos.length;

				for (var i=0; i< hijos_tam; i++){
					var tipo = hijos.item(i).nodeName;
					if(tipo==="INPUT"){

						if(hijos.item(i).value > 0){
							id_input_seleccionado =  hijos.item(i).id;
							btn_especialidad_precionado = true;
						}
						break;
					}
				}
			});
		}
		if(btn_especialidad_precionado){
			if($('#form_voluntario')[0].checkValidity()){
				var slots = $(".slot-dia");
				var slot_seleccionado = false;

				for(var i=0;i<slots.length;i++){
					let slot_classes = slots[i].classList;

					if(slot_classes.contains("active")){
						slot_seleccionado=true;
						break;
					}
				}

				if(slot_seleccionado){
					var telefono = $("#telefono").val();
					telefono = telefono.replace(/[^0-9]/g,'');
					$("#telefono").val(telefono);
					if(telefono.length == 10){
						//se valida el formato de la fecha
						var fecha_nacimiento = $("#fecha_nacimiento").val();
						//se quita lo que no se ocupa
						fecha_nacimiento = fecha_nacimiento.replace(/[^0-9-]/g,'');
						$("#fecha_nacimiento").val(fecha_nacimiento);
						var regex_fecha_nacimiento = RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2})$');
						if(regex_fecha_nacimiento.test(fecha_nacimiento)){
							//se valida el correo tenga estructura  x@y
							var email = $('#email').val();
							//se quitan todos los espacios
							email = email.replace(/\s/g,'');
							$('#email').val(email);
							var regex_email = RegExp('(.+)@(.+)$');
							if(regex_email.test(email)){
								//se valida el password
								var password = $("#password").val();
								if(password.length>=8 && password.length<=12){
									var confirm_password = $("#confirm_password").val();
									if(confirm_password===password){
										if(is_especializado){
											$('<input />').attr('type', 'hidden')
										 	.attr('name', "especialidad")
										 	.attr('value', id_input_seleccionado)
										 	.appendTo('#form_voluntario');
										}
										$("#form_voluntario").submit();
									}else{
										crearMensaje(true,"Error","La contraseña ingresada no coincide con la de Confirma contraseña",5000);
									}
								}else{
									crearMensaje(true,"Error","La contraseña debe de tener al menos 8 caracteres y no más de 12 caracteres",5000);
								}
							}else{
								crearMensaje(true,"Error","El correo ingresado ("+email+") debe tener la estructura adecuada",5000);
							}

						}else{
							crearMensaje(true,"Error","La fecha ingresada ("+fecha_nacimiento+") debe tener el formato aaaa-mm-dd (año-mes-dia) ejemplo 1995-05-10",6000);
						}
					}else{
						crearMensaje(true,"Error","El número celular ingresado ("+telefono+") debe ser numerico a 10 digítos",5000);
					}
				}else{
					crearMensaje(true,"Error","Debes de seleccionar por lo menos 1 horario");
			        document.getElementById('horario').scrollIntoView();
				}

			}else{
				var invalid_inputs = $("input:invalid");
				var valid_inputs = $("input:valid");
				var mensaje_error = '<ol id=mensaje>';
				var tipo_text_msg = false;
				var tipo_checkbox_msg = false;
				var tipo_email_msg = false;
				var tipo_date_msg = false;
				var tipo_password_msg = false;

				invalid_inputs.each(function(i, obj){
					var tipo = obj.type;
					var id = obj.id;
					if(tipo !=="checkbox"){
						$("#"+id).css("border","2px solid #ef5350");
					}else{
						$("#"+id).css("outline","2px solid #ef5350");
						$("#"+id).css("width","15px");
						$("#"+id).css("height","15px");
					}

					if(tipo=="text" && !tipo_text_msg){
						tipo_text_msg=true;
						mensaje_error+="<li>Completa los campos faltantes</li>";
					}

					if(tipo=="checkbox" && !tipo_checkbox_msg){
						tipo_checkbox_msg=true;
						mensaje_error+="<li>Debes aceptar los terminos y condiciones</li>";
					}

					if(tipo=="email" && !tipo_email_msg){
						tipo_email_msg=true;
						mensaje_error+="<li>El correo debe tener la estructura adecuada</li>";
					}

					if(tipo=="date" && !tipo_date_msg){
						tipo_date_msg=true;
						mensaje_error+="<li>No puede dejar la fecha vacia</li>";
					}

					if(tipo=="password" && !tipo_password_msg){
						tipo_password_msg=true;
						mensaje_error+="<li>No puede dejar el campo de Contraseña ó Confirma contraseña vacios</li>";
					}
				});

				mensaje_error+="</ol>";
				crearMensaje(true,"Corrigue los errores para continuar",mensaje_error,6000);
				valid_inputs.each(function(i, obj){
					var tipo = obj.type;
					var id = obj.id;
					if(tipo !=="checkbox"){
						$("#"+id).css("border","none");
					}else{
						$("#"+id).css("outline","none");


					}
				});

			}
		}else{
			document.getElementById('especialidad_container_title').scrollIntoView();
			crearMensaje(true,"Error","Selecciona una Especialidad para continuar");
		}
	});

	$("#form_voluntario input[type=text]").on("keyup focus blur", function() {
		//console.log($(this));
		var is_valid = $(this)[0].validity.valid;
		if(is_valid){
			$(this).css("border","2px solid #71415a");
		}else{
			$(this).css("border","2px solid #ef5350");
		}
	});

	$("#form_voluntario input[type=password]").on("keyup focus blur", function() {
		//console.log($(this));
		var is_valid = $(this)[0].validity.valid;
		if(is_valid){
			$(this).css("border","2px solid #71415a");
		}else{
			$(this).css("border","2px solid #ef5350");
		}
	});

	$("#form input[type=email]").on("keyup focus blur", function() {
        //console.log($(this));
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("border","2px solid #71415a");
        }else{
            $(this).css("border","2px solid #ef5350");
        }
    });

	$("#form_voluntario input[type=date]").change(function(){
		var is_valid = $(this)[0].validity.valid;
		if(is_valid){
			$(this).css("border","2px solid #71415a");
		}else{
			$(this).css("border","2px solid #ef5350");
		}
	});

	$("#form_voluntario input[type=checkbox]").change(function(){
		var is_valid = $(this)[0].validity.valid;
		if(is_valid){
			$(this).css("outline","2px solid #71415a");
		}else{
			$(this).css("outline","2px solid #ef5350");
			$(this).css("width","15px");
			$(this).css("height","15px");
		}
	});


	/*********************************Especialidad*******************************************/
	$(".btn_especialidad").click(function(){
		var botones_especialidad = $(".btn_especialidad");
		var input_boton_precionado = $(this).find("input");
		var input_boton_precionado_id = input_boton_precionado[0].id;
		$(this).addClass('active');

		botones_especialidad.each(function(indice,boton){
			var hijos = boton.firstElementChild.children;
			var hijos_tam = hijos.length;

			for (var i=0; i< hijos_tam; i++){
				var tipo = hijos.item(i).nodeName;
				if(tipo==="INPUT"){
					input_id = hijos.item(i).id;
					if(input_id===input_boton_precionado_id){
						hijos.item(i).value = 1;

					}else{
						hijos.item(i).value = 0;
						$("#"+input_id).closest('.btn_especialidad').removeClass('active');
					}
					break;
				}
			}
		});
	});


});
