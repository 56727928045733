$(window).ready(function(){
	var curLocation = [0, 0];

    if (curLocation[0] == 0 && curLocation[1] == 0) {
        curLocation = [19.430556,-99.133030];
    }

    var mapboxmap_movil = document.getElementById("MapLocation");
    if(mapboxmap_movil){
    	var map_movil = L.map('MapLocation', { zoomControl:true }).setView(curLocation, 11);
    	map_movil.scrollWheelZoom.disable();
        L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {maxZoom: 19,minZoom: 10,}).addTo(map_movil);

		
		var source = $.ajax({
			type: 'GET',
			url: base_url+'/functions_map/getSource'
			}).fail(function(err,err_2,err_3){
				console.log(err);
				console.log(err_2);
				console.log(err_3);
			}).done(function(response){
				source = null;
				if(response.sucess){
					source = response;
				}
				pintarPoligonosMovil(source);
		});

		function pintarPoligonosMovil(source){
			if(source!==null){
				var featureGroup_movil = L.layerGroup();
				 for (var key in source) {
                if(typeof(source[key].polygon)!=="undefined"){
                    var myLines = [{
                        "type":source[key].type,
                        "coordinates": JSON.parse(source[key].polygon)
                    }];                
                    var myStyle = {
                        "fillColor": "#f5a623",
                        "weight": 1,
                        "fillOpacity": 0.5,
                        "color": "#FFFFFF",
                        "opacity": 0.8  
                    };
                    
                    var polygon = L.geoJSON(myLines, {
                        style: myStyle,
                    });

                    polygon.addTo(featureGroup_movil);
                    
                }
            }
            featureGroup_movil.addTo(map_movil);
			}
		}

		map_movil.attributionControl.setPrefix(false);

		const color_marcador = 'rgba(27,182,0,0.3)';

		const estilos_marcador = `
          background-color: ${color_marcador};
          width: 6.2rem;
          height: 6.2rem;
          display: block;
          left: -3.1rem;
          top: -3.1rem;
          position: relative;
          border-radius: 6.2rem;
          transform: rotate(45deg);
          border: 1px solid #FFFFFF`;

        const icon = L.divIcon({
          className: "marcador-sentika",
          iconAnchor: [0, 24],
          labelAnchor: [-6, 0],
          popupAnchor: [0, -36],
          html: `<span style="${estilos_marcador}" />`
        });
        
        var marker = new L.marker(curLocation, {
            icon: icon,
            draggable: 'true'
        });

        marker.on('dragend', function(event) {
          var position = marker.getLatLng();
          //console.log(position);
          marker.setLatLng(position, {
            draggable: 'true'
          }).bindPopup(position).update();
          $("#Latitude").val(position.lat);
          $("#Longitude").val(position.lng).keyup();
        });

        $("#Latitude, #Longitude").change(function() {
          var latitude = isNaN(parseFloat($("#Latitude").val()))?19.430556:parseFloat($("#Latitude").val());
          var longitude = isNaN(parseFloat($("#Longitude").val()))?-99.133030:parseFloat($("#Longitude").val());
          var position = [latitude,longitude];
          //console.log(position);
          marker.setLatLng(position, {
            draggable: 'true'
          }).bindPopup(position).update();
          map_movil.panTo(position);
        });

        map_movil.on('zoomend', function() {
            var zoom_level = map_movil.getZoom();
            //console.log('zoomend: ', zoom_level );
            if( zoom_level === 9 )
            {
                $('.marcador-sentika span').css({
                                'width': '3em',
                                'height': '3em',
                                'border-radius': '3em',
                                'top': '-1.5em',
                                'left': '-1.5em'
                            });
            }
            else if( zoom_level === 10 )
            {
                $('.marcador-sentika span').css({
                                'width': '4em',
                                'height': '4em',
                                'border-radius': '4em',
                                'top': '-2em',
                                'left': '-2em'
                            });
            }
            if( zoom_level === 11 )
            {
                $('.marcador-sentika span').css({
                                'width': '6.2em',
                                'height': '6.2em',
                                'border-radius': '6.2em',
                                'top': '-3.1em',
                                'left': '-3.1em'
                            });
            }
            if( zoom_level === 12 )
            {
                $('.marcador-sentika span').css({
                                'width': '10em',
                                'height': '10em',
                                'border-radius': '10em',
                                'top': '-5em',
                                'left': '-5em'
                            });
            }
            else if( zoom_level === 13 )
            {
                $('.marcador-sentika span').css({
                                'width': '15em',
                                'height': '15em',
                                'border-radius': '15em',
                                'top': '-7.5em',
                                'left': '-7.5em'
                            });
            }
            else if( zoom_level === 14 )
            {
                $('.marcador-sentika span').css({
                                'width': '25em',
                                'height': '25em',
                                'border-radius': '25em',
                                'top': '-12.5em',
                                'left': '-12.5em'
                            });
            }
            else if( zoom_level === 15 )
            {
                $('.marcador-sentika span').css({
                                'width': '45em',
                                'height': '45em',
                                'border-radius': '45em',
                                'top': '-22.5em',
                                'left': '-22.5em'
                            });
            }
            else if( zoom_level === 16 )
            {
                $('.marcador-sentika span').css({
                                'width': '80em',
                                'height': '80em',
                                'border-radius': '80em',
                                'top': '-40em',
                                'left': '-40em'
                            });
            }
            else if( zoom_level === 17 )
            {
                $('.marcador-sentika span').css({
                                'width': '120em',
                                'height': '120em',
                                'border-radius': '120em',
                                'top': '-60em',
                                'left': '-60em'
                            });
            }
            else if( zoom_level === 18 )
            {
                $('.marcador-sentika span').css({
                                'width': '170em',
                                'height': '170em',
                                'border-radius': '170em',
                                'top': '-85em',
                                'left': '-85em'
                            });
            }
        });
        map_movil.addLayer(marker);
    }

    $("#submit_transporte").click(function(){
        if($('#form')[0].checkValidity()){
            //validacion de telefono
            var telefono = $("#telefono").val();
            telefono = telefono.replace(/[^0-9]/g,'');
            $("#telefono").val(telefono);
            if(telefono.length == 10){
                //se valida el formato de la fecha
                var fecha_nacimiento = $("#fecha_nacimiento").val();
                //se quita lo que no se ocupa
                fecha_nacimiento = fecha_nacimiento.replace(/[^0-9-]/g,'');
                $("#fecha_nacimiento").val(fecha_nacimiento);
                var regex_fecha_nacimiento = RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2})$');
                if(regex_fecha_nacimiento.test(fecha_nacimiento)){
                    //se valida el correo tenga estructura  x@y
                    var email = $('#email').val();
                    //se quitan todos los espacios
                    email = email.replace(/\s/g,'');
                    $('#email').val(email);
                    var regex_email = RegExp('(.+)@(.+)$');
                    if(regex_email.test(email)){
                        //se valida el tipo de transporte
                        var tipo_transporte = $("#tipo_transporte").val();
                        if(tipo_transporte!=="" && (tipo_transporte=="Bicicleta" || tipo_transporte=="Motocicleta")){
                            //se valida la longitud y la latitud
                            var latitud = $('#Latitude').val();
                            var longitud = $('#Longitude').val();
                            //se quita lo que no sirve
                            latitud = latitud.replace(/[^0-9.-]/g,'');
                            longitud = longitud.replace(/[^0-9.-]/g,'');
                            $('#Latitude').val(latitud);
                            $('#Longitude').val(longitud);
                            //regex que valida que puede empezar con un - seguido de n cantidad de números y despues puede tener un .seguido de n cantidan de números
                            var regex_latlon = RegExp('^-?[0-9]+(.?[0-9]+)$');
                            //si tienen la estructura deseada
                            if(regex_latlon.test(latitud) && regex_latlon.test(longitud)){
                                //se valida que la latitud y longitud este entre sus valores maximos y minimos
                                if(latitud >=-90 && latitud <=90 && longitud >=-180 && longitud <=180){
                                    //se valida el password
                                    var password = $("#password").val();
                                    if (password.length>=8 && password.length<=12){
                                        var confirm_password = $("#confirm_password").val();
                                        if(confirm_password===password){
                                            $("#form").submit();
                                        }else{
                                            crearMensaje(true,"Error","La contraseña ingresada no coincide con la de Confirma contraseña",5000);
                                        }
                                    }else{
                                        crearMensaje(true,"Error","La contraseña debe de tener al menos 8 caracteres y no más de 12 caracteres",5000);
                                    }
                                }else{
                                	crearMensaje(true,"Error","El valor de la Latitud o el de la longitud no son validos",5000);
                                }
                            }else{
                            	crearMensaje(true,"Error","El valor de la latitud o longitud no tiene la estructura adecuada",5000);
                            }
                        }else{
                        	crearMensaje(true,"Error","Debes seleccionar un tipo de transporte correcto",5000);
                        }
                    }else{
                    	crearMensaje(true,"Error","El correo ingresado ("+email+") debe tener la estructura adecuada",5000);
                    }
                }else{
                	crearMensaje(true,"Error","La fecha ingresada ("+fecha_nacimiento+") debe tener el formato aaaa-mm-dd (año-mes-dia) ejemplo 1995-05-10",6000);
                }
            }else{
            	crearMensaje(true,"Error","El número celular ingresado ("+telefono+") debe tener 10 dígitos, por ejemplo: 5512345678",5000);
            }
        }else{
            var mensaje_error = '<ol id=mensaje>';
            var tipo_text_msg = false;
            var tipo_checkbox_msg = false;
            var tipo_email_msg = false;
            var tipo_date_msg = false;
            var tipo_select_msg = false;
            var tipo_password_msg = false;

            var invalid_inputs = $("input:invalid");
            var invalid_selects = $("select:invalid");
            var valid_inputs = $("input:valid");
            var valid_selects = $("select:valid");

            invalid_inputs.each(function(i, obj){
                var tipo = obj.type;
                var id = obj.id;
                if(tipo !=="checkbox"){
                    $("#"+id).css("border","2px solid #ef5350");
                }else{
                    $("#"+id).css("outline","2px solid #ef5350");
                    $("#"+id).css("width","15px");
                    $("#"+id).css("height","15px");     
                }

                if(tipo=="text" && !tipo_text_msg){
                    tipo_text_msg=true;
                    mensaje_error+="<li>Completa los campos faltantes</li>";
                }

                if(tipo=="checkbox" && !tipo_checkbox_msg){
                    tipo_checkbox_msg=true;
                    mensaje_error+="<li>Debes aceptar los terminos y condiciones</li>";
                }

                if(tipo=="email" && !tipo_email_msg){
                    tipo_email_msg=true;
                    mensaje_error+="<li>El correo debe tener la estructura adecuada</li>";
                }

                if(tipo=="date" && !tipo_date_msg){
                    tipo_date_msg=true;
                    mensaje_error+="<li>No puede dejar la fecha vacia</li>";
                }

                if(tipo=="password" && !tipo_password_msg){
                    tipo_password_msg=true;
                    mensaje_error+="<li>No puede dejar el campo de Contraseña ó Confirma contraseña vacios</li>";
                }
            });

            invalid_selects.each(function(i, obj){
                var tipo = obj.type;
                var id = obj.id;
                $("#"+id).css("outline","2px solid #ef5350");

                if(!tipo_select_msg){
                    tipo_select_msg=true;
                    mensaje_error+="<li>Debe seleccionar una opción en Medio de transporte</li>";
                }
            });

            mensaje_error+="</ol>";
            crearMensaje(true,"Corrigue los errores para continuar",mensaje_error,6000);

            valid_selects.each(function(i, obj){
                var tipo = obj.type;
                var id = obj.id;
                $("#"+id).css("outline","none");
            });

            valid_inputs.each(function(i, obj){
                var tipo = obj.type;
                var id = obj.id;
                if(tipo !=="checkbox"){
                    $("#"+id).css("border","none");
                }else{
                    $("#"+id).css("outline","none");  
                }
            });
            
            }
        
        
        
    });

    
    $("#form input[type=text]").on("keyup focus blur", function() {
        //console.log($(this));
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("border","none");
        }else{
            $(this).css("border","2px solid #ef5350");
        }
        
    });

    $("#form_voluntario input[type=password]").on("keyup focus blur", function() {
        //console.log($(this));
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("border","none");
        }else{
            $(this).css("border","2px solid #ef5350");
        }
    });

    $("#form input[type=email]").on("keyup focus blur", function() {
        //console.log($(this));
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("border","none");
        }else{
            $(this).css("border","2px solid #ef5350");
        }
    });

    $("#form select").on("change focus blur", function() {
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("outline","none");
        }else{
            $(this).css("outline","2px solid #ef5350");
        }
        
    });

    $("#form input[type=date]").change(function(){
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("border","none");
        }else{
            $(this).css("border","2px solid #ef5350");
        }
    });

    $("#form input[type=checkbox]").change(function(){
        var is_valid = $(this)[0].validity.valid;
        if(is_valid){
            $(this).css("outline","none");
        }else{
            $(this).css("outline","2px solid #ef5350");
            $(this).css("width","15px");
            $(this).css("height","15px");
        }
    });


});	