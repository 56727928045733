/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.tippy = require('./tippy.js');
require('./leaflet');
require('./general');
require('./codigo_sms');
require('./transporte');
require('./fija_esp');
require('./ver_perfil');
require('./map');
require('./remover');
require('./simulacro');
require('./components/functions-board.js');
require('./components/info-tabs.js');
require('./components/tablero-emergencias.js');
require('./components/mensajes.js');
require('./components/numeralia.js');
require('./edit_perfil');




//import 'bootstrap';
import * as d3 from 'd3';
window.d3 = d3;

import 'bootstrap';

window.$ = window.jQuery = require('jquery');
