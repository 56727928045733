$(window).ready(function(){
	window.crearMensaje = function (error,titulo,mensaje,tiempo=3000){
		var clase_mensaje = error==true?"alert-danger":"alert-success";
		var mensaje_alert = '<div class="alertaActivacion alert msj_js '+clase_mensaje+'">';
		mensaje_alert += '<strong id="mensaje_negritas" style="font-size:16px;">'+titulo+'</strong>';
		mensaje_alert += '<p id="mensaje" style="font-size:14px;">'+mensaje+'</p>';
		mensaje_alert += '</div>';
		$("body").append(mensaje_alert);
		$(".msj_js").show();
        setTimeout(function(){
        	$(".msj_js").remove();
        }, tiempo);
	}

	/***************************************Login*****************************************/
	var csrf = document.getElementById('csrf_token');
	if(csrf){
		var template = '<form action="'+base_url+'/autenticate" method="post" id="form_login">';
			template+='<input type="hidden" name="_token" value="'+csrf.value+'">';
			template+='<div class="col-md-12 input-field-array" align="left">';
				template+='<label for="telefono_login"><b>Usuario</b></label>';
				template+='<input class="input_login" type="text" name="telefono_login" id="telefono_login" placeholder="Ingresa tu telefono" required>';
			template+='</div>';
			template+='<div class="col-md-12 input-field-array" align="left">';
				template+='<label for="password_login"><b>Contraseña</b></label>';
				template+='<input class="input_login" type="password" name="password_login" id="password_login" placeholder="Ingresa tu contraseña" required>';
			template+='</div>';
		template+='</form>';
		template+='<div class="row mt-2">';
			template+='<div class="col-12">';
				template+='<a href="'+base_url+'/password/reset" style="font-size:12px;color:#71415a">¿olvidaste tu contraseña?</a>';
			template+='</div>';
			template+='<div class="col-12 mt-3" align="right">';
				template+='<button class="btn btn-large ml-auto" type="button" id="btn_autenticar" style="background-color:#71415a;border-radius: 10000px;width:100px;color:#fff;">Entrar</button>';
			template+='</div>';
		template+='</div>';

		tippy("#login_btn", {
	        content: template,
	        animation: 'perspective',
	        aria: null,
	        trigger: 'click',
	        hideOnClick: true,
	        interactive: true,
	        zIndex: 999,
	        onShow(tip) {
	        	$("#login_btn").css('background-color','#0f4c42');
			 },
		  	onHide(tip) {
		    	$("#login_btn").css('background-color','#f0f0f0');
		  	},
	        theme: 'custom'
    	});

    	$(document).on("click","#btn_autenticar",function(e) {
    		var telefono = $("#telefono_login").val();
			var password = $("#password_login").val();
			telefono = telefono.replace(/[^0-9]/g,'');
			$("#telefono").val(telefono);
			if(telefono.length == 10){
				if(password.length>=8 && password.length<=12){
					$("#form_login").submit();
				}else{
					crearMensaje(true,"Error","La contraseña debe de tener al menos 8 caracteres y no más de 12 caracteres",5000);
				}
			}else{
				crearMensaje(true,"Error","El número celular ingresado ("+telefono+") debe ser numerico a 10 digítos",5000);
			}
    	});
	}


	var template_logout = '<div class="row">';
			template_logout += '<div class="col-12">';
				template_logout += '<a href="'+base_url+'/Perfil" style="text-decoration:none !important;"><img class="mt-1" src="'+base_url+'/images/ojo.svg" alt="" align="left"> Ver mi perfil</a>';
				template_logout += '</div>';
			template_logout += '<div class="col-12 mt-2">';
				template_logout += '<a href="'+base_url+'/logout" style="text-decoration:none !important;"><img class="mt-1" src="'+base_url+'/images/tache.svg" alt="" align="left"> Cerrar session</a>';
			template_logout += '</div>';
		template_logout += '</div>';

    tippy('#logout_btn',{
    	content: template_logout,
    	animation: 'perspective',
        aria: null,
        trigger: 'click',
        hideOnClick: true,
        interactive: true,
        onShow(tip) {
        	$("#logout_btn").css('background-color','transparent');
        	$("#logout_btn").css('box-shadow','none');
        	tip.set({ maxWidth: 200 });
		 },
        theme: 'custom'
    });

    /**
     * BTN tab info / mensajes
     */

    $('.btn-tab-info').on('click', function(event){
    	var tab = $(this).attr('data-tab');
    	$('.btn-tab-info').removeClass('active');
    	$('.tab-selected').removeClass('active');
		$('.'+tab).addClass('active');
		
		let description = tab == 'info-selected' ? 'Aquí encontrarás la información sobre Infraestructura, Daños, Costos y Necesidades de la Alcaldía, Coordinación o Cuadrante seleccionado en el mapa' : 'En esta sección puedes mandar mensajes SMS a los grupos de contactos que elijas';

		$("#tab-description").text(description);
    });
});
