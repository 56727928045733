/** ** *** ** *** ** *** * **
*** TEMPLATES INFO CLICKS  *
*** ** *** ** *** ** *** * */

window.Tab = {};

/**
 * [template description]
 *
 * @return {[type]}
 */
Tab.template = function (tab_template, data)
{
    this.data = data;

    this.key = tab_template;

    if(tab_template == 'centros_de_salud' || tab_template == 'hospitales')
        return this.centrosSalud();

    if(tab_template == 'refugios')
        return this.refugios();

    if(tab_template == 'centros_de_acopio')
        return this.centrosAcopio();

    if(tab_template == 'centros_de_mando')
      return this.centrosMando();

    if(tab_template == 'jefes_de_cuadrante')
        return this.jefesCuadrante();

    if(tab_template == 'heróico_cuerpo_de_bomberos')
        return this.bomberos();

    if(tab_template == 'brigadistas_ERUM')
        return this.erum();

    if(tab_template == 'promotores_de_participacion_ciudadana')
        return this.promotores();

    if(tab_template == 'voluntarios_sentika_fijos')
        return this.sentikaFijos();

    if(tab_template == 'voluntarios_sentika_moviles')
        return this.sentikaMoviles();

    if(tab_template == 'voluntarios_sentika_especializados')
        return this.sentikaEspecializados();

    if(tab_template == 'empresas_reconstruccion')
        return this.empresasReconstruccion();

    if(tab_template == 'empresas_obra_pública')
        return this.empresasObraPublica();

    if(tab_template == 'daños_estimados')
        return this.danosEstimados();

    if(tab_template == 'necesidades_estimados')
        return this.necesidadesEstimados();

    if(tab_template == 'vivienda' || tab_template == 'centros_de_Trabajo')
        return this.vivienda();

    if(tab_template == 'escuelas' || tab_template == 'inmuebles_públicos')
        return this.vivienda();

    if(tab_template == 'vivienda_edificio')
        return this.vivienda();

    if(tab_template == 'decesos' || tab_template == 'heridos')
        return this.costosHumanos();

    if(tab_template == 'personas_desaparecidas')
        return this.costosHumanos();

    if(tab_template == 'agua_y_bebidas' || tab_template == 'alimentos_no_perecederos' || tab_template == 'mascotas' || tab_template == 'kit_de_higiene' || tab_template == 'kit_de_dormir' || tab_template == 'medios_de_transporte' || tab_template == 'alimentos_percederos' || tab_template == 'herramientas')
      return this.necesidades();
};


/**
 * Tempalte Centros de Salud
 */
Tab.centrosSalud = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Tipo</th>';
          navTabs += '<th>Sistema</th>';
          navTabs += '<th>Capacidad</th>';
          navTabs += '<th>Ubicación</th>';
          navTabs += '<th>Alcaldia</th>';
          navTabs += '<th>Teléfono</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Tipo</span>'+value.tipounieco+'</td>';
              navTabs += '<td><span class="d-sm-none">Sistema</span>'+value.sistema+'</td>';
              navTabs += '<td><span class="d-sm-none">Capacidad</span>'+value.capacidad+'</td>';
              navTabs += '<td><span class="d-sm-none">Ubicación</span>'+value.ubicacion+'</td>';
              navTabs += '<td><span class="d-sm-none">Alcaldía</span>'+value.alcaldia+'</td>';
              navTabs += '<td><span class="d-sm-none">Teléfono</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Refujios
 */
Tab.refugios = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Capacidad</th>';
          navTabs += '<th>Uso inmueble</th>';
          navTabs += '<th>Colonia</th>';
          navTabs += '<th>Alcaldia</th>';
          navTabs += '<th>Región</th>';
          navTabs += '<th>Cuadrante</th>';
          navTabs += '<th>Instalado</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {

            let checked = value.instalacion_completa == true ? 'checked' : '';

            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Capacidad</span>'+value.capacidad_refugio+'</td>';
              navTabs += '<td><span class="d-sm-none">Uso inmueble</span>'+value.uso_inmueb+'</td>';
              navTabs += '<td><span class="d-sm-none">Colonia</span>'+value.colonia+'</td>';
              navTabs += '<td><span class="d-sm-none">Alcaldía</span>'+value.alcaldia+'</td>';
              navTabs += '<td><span class="d-sm-none">Región</span>'+value._region+'</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
              navTabs += `<td class="refugios">
                            <div class="custom-control custom-switch">
                              <input type="checkbox" value="${value.id}" class="custom-control-input centro_acopio" id="activarRefugio${key}" ${checked} data-type="Refugio">
                              <label class="custom-control-label" for="activarRefugio${key}"></label>
                            </div>
									        </td>`;
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Centros de acopio
 */
Tab.centrosAcopio = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Alcaldia</th>';
          navTabs += '<th>Región</th>';
          navTabs += '<th>Cuadrante</th>';
          navTabs += '<th>Instalado</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
          
            let checked = value.instalacion_completa == true ? 'checked' : '';

            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Alcaldía</span>'+value.alcaldia+'</td>';
              navTabs += '<td><span class="d-sm-none">Región</span>'+value._region+'</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
              navTabs += `<td class="centrosAcopio">
                            <div class="custom-control custom-switch">
                              <input type="checkbox" value="${value.id}" class="custom-control-input centro_acopio" id="activarCentroAcopio${key}" ${checked} data-type="CentroAcopio">
                              <label class="custom-control-label" for="activarCentroAcopio${key}"></label>
                            </div>
									        </td>`;
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Refujios
 */
Tab.centrosMando = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
        navTabs += '<th>Nombre</th>';
        navTabs += '<th>Alcaldia</th>';
        navTabs += '<th>Región</th>';
        navTabs += '<th>Cuadrante</th>';
          navTabs += '<th>Instalado</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {

            let checked = value.instalacion_completa == true ? 'checked' : '';

            navTabs += '<tr>';
            navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
            navTabs += '<td><span class="d-sm-none">Alcaldía</span>'+value.alcaldia+'</td>';
            navTabs += '<td><span class="d-sm-none">Región</span>'+value._region+'</td>';
            navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
              navTabs += `<td class="centrosMando">
                            <div class="custom-control custom-switch">
                              <input type="checkbox" value="${value.id}" class="custom-control-input centro_acopio" id="activarCentroMando${key}" ${checked} data-type="CentroMando">
                              <label class="custom-control-label" for="activarCentroMando${key}"></label>
                            </div>
									        </td>`;
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Jefes de cuadrante
 */
Tab.jefesCuadrante = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre de policía</th>';
          navTabs += '<th>Placa</th>';
          navTabs += '<th>Teléfono</th>';
          navTabs += '<th>Nextel</th>';
          navTabs += '<th>Recibió capacitación</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre de policía</span>'+value.nombre_jefe_cuadrante+'</td>';
              navTabs += '<td><span class="d-sm-none">Placa</span>'+value.placa_jefe_cuadrante+'</td>';
              navTabs += '<td><span class="d-sm-none">Teléfono</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Nextel</span>'+value.nextel+'</td>';
              navTabs += '<td><span class="d-sm-none">Recibió capacitación</span>'+value.fecha_capacitacion+'</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Bomberos
 */
Tab.bomberos = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre estación</th>';
          navTabs += '<th>Jefe de estación</th>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Número celular</th>';
          navTabs += '<th>Número fijo</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre estación</span>'+value.estacion+'</td>';
              navTabs += '<td><span class="d-sm-none"></span>'+value.jefe_de_estacion+'</td>';
              navTabs += '<td><span class="d-sm-none">Jefe de estación</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Número celular</span>'+value.numero_celular+'</td>';
              navTabs += '<td><span class="d-sm-none">Número fijo</span>'+value.numero_fijo+'</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte brigadistas Erum
 */
Tab.erum = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Institución de emergencia asignada</th>';
          navTabs += '<th>Sector</th>';
          navTabs += '<th>Función</th>';
          navTabs += '<th>Especialidad</th>';
          navTabs += '<th>Responsable asignado</th>';
          navTabs += '<th>Teléfono 1</th>';
          navTabs += '<th>Teléfono 2</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Institución de emergencia asignada</span>'+value.institucion_emergencia+'</td>';
              navTabs += '<td><span class="d-sm-none">Sector</span>'+value.sector+'</td>';
              navTabs += '<td><span class="d-sm-none">Función</span>'+value.funcion+'</td>';
              navTabs += '<td><span class="d-sm-none">Especialidad</span>'+value.especialidad+'</td>';
              navTabs += '<td><span class="d-sm-none">Responsable asignado</span>'+value.personal_asignado+'</td>';
              navTabs += '<td><span class="d-sm-none">Teléfono 1</span><a href="tel:'+value.telefono_1+'" class="text-reset">'+value.telefono_1+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Teléfono 2</span><a href="tel:'+value.telefono_2+'" class="text-reset">'+value.telefono_2+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Promotores
 */
Tab.promotores = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Tipo</th>';
          navTabs += '<th>Celular</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Tipo</span>'+value.tipo+'</td>';
              navTabs += '<td><span class="d-sm-none">Celular</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Voluntarios Sentika fijos
 */
Tab.sentikaFijos = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Celular</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Celular</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.nomenclatu+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Voluntarios Sentika Moviles
 */
Tab.sentikaMoviles = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Teléfono</th>';
          navTabs += '<th>Correo electrónico</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Teléfono</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
              navTabs += '<td class="text-break"><span class="d-sm-none">Correo electrónico</span><a target="_blank" rel="noopener noreferrer" href="mailto:'+value.email+'?subject=Sentika" class="text-reset">'+value.email+'</a></td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Voluntarios Sentika Especializados
 */
Tab.sentikaEspecializados = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Celular</th>';
          navTabs += '<th>Especialidad</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Celular</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Especialidad</span>'+value.especialidad+'</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.nomenclatu+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Empresas en reconstruccion
 */
Tab.empresasReconstruccion = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre empresa</th>';
          navTabs += '<th>Teléfono 1</th>';
          navTabs += '<th>Teléfono 2</th>';
          navTabs += '<th>Email</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre empresa</span>'+value.nombre_empresa+'</td>';
              navTabs += '<td><span class="d-sm-none">Teléfono 1</span><a href="tel:'+value.telefono+'" class="text-reset">'+value.telefono+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Teléfono 2</span><a href="tel:'+value.telefono2+'" class="text-reset">'+value.telefono2+'</a></td>';
              navTabs += '<td class="text-break"><span class="d-sm-none">Correo electrónico</span><a target="_blank" rel="noopener noreferrer" href="mailto:'+value.email+'?subject=Sentika" class="text-reset">'+value.email+'</a></td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';

            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};


/**
 * Tempalte Empresas obra publica
 */
Tab.empresasObraPublica = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre obra</th>';
          navTabs += '<th>Tipo de trabajo</th>';
          navTabs += '<th>Plazo</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre obra</span>'+value.nombre_obra+'</td>';
              navTabs += '<td><span class="d-sm-none">Tipo de trabajo</span>'+value.tipo_trabajo+'</td>';
              navTabs += '<td><span class="d-sm-none">Plazo</span>-</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};


/**
 * Tempalte Daños estimados
 */
Tab.danosEstimados = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre de daño</th>';
          navTabs += '<th>Número</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre de daño</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Número</span>'+value.count+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Necesidades estimados
 */
Tab.necesidadesEstimados = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Tipo de necesidad</th>';
          navTabs += '<th>Número</th>';
          navTabs += '<th>Cuadrante</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Tipo de necesidad</span>'+value.name+'</td>';
              navTabs += '<td><span class="d-sm-none">Número</span>'+value.count+'</td>';
              navTabs += '<td><span class="d-sm-none">Cuadrante</span>'+value.cuadrante+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte daños
 */
Tab.vivienda = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Alcaldia</th>';
          navTabs += '<th>Calle</th>';
          navTabs += '<th>Colonia</th>';
          navTabs += '<th>Daño</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+'</td>';
              navTabs += '<td><span class="d-sm-none">Alcaldía</span>'+value.alcaldia+'</td>';
              navTabs += '<td><span class="d-sm-none">Calle</span>'+value.calle+'</td>';
              navTabs += '<td><span class="d-sm-none">Colonia</span>'+value.colonia+'</td>';
              navTabs += '<td><span class="d-sm-none">Daño</span>'+value.daño+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Costos humanos
 */
Tab.costosHumanos = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Nombre</th>';
          navTabs += '<th>Edad</th>';
          navTabs += '<th>Género</th>';
          navTabs += '<th>Alcaldia</th>';
          navTabs += '<th>Calle</th>';
          navTabs += '<th>Colonia</th>';
          navTabs += '<th>Seguimiento</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Nombre</span>'+value.nombre+' '+value.paterno+' '+value.materno+'</td>';
              navTabs += '<td><span class="d-sm-none">Edad</span>'+value.edad+'</td>';
              navTabs += '<td><span class="d-sm-none">Género</span>'+value.genero+'</td>';
              navTabs += '<td><span class="d-sm-none">Alcaldía</span>'+value.alcaldia+'</td>';
              navTabs += '<td><span class="d-sm-none">Calle</span>'+value.calle+'</td>';
              navTabs += '<td><span class="d-sm-none">Colonia</span>'+value.colonia+'</td>';
              navTabs += '<td><span class="d-sm-none">Seguimiento</span>'+value.seguimiento+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};

/**
 * Tempalte Necesidades estimados
 */
Tab.necesidades = function ()
{
  var navTabs = '<div id="'+this.key+'" class="tab-info-click tab-pane fade table-responsive">';
    navTabs += '<table  class="w-100 table">';
      navTabs += '<thead>';
        navTabs += '<tr>';
          navTabs += '<th>Cantidad</th>';
          navTabs += '<th>Caracteristica</th>';
          navTabs += '<th>Centro Acopio</th>';
          navTabs += '<th>Insumo</th>';
          navTabs += '<th>Unidad</th>';
          navTabs += '<th>Clasificación</th>';
          navTabs += '<th>Urgencia</th>';
        navTabs += '</tr>';
      navTabs += '</thead>';
      navTabs += '<tbody>';
        $.each(this.data, function( key, value ) {
            navTabs += '<tr>';
              navTabs += '<td><span class="d-sm-none">Cantidad</span>'+value.cantidad+'</td>';
              navTabs += '<td><span class="d-sm-none">Característica</span>'+value.caracteristicas+'</td>';
              navTabs += '<td><span class="d-sm-none">Centro Acopio</span>'+value.centro_acopio+'</td>';
              navTabs += '<td><span class="d-sm-none">Insumo</span>'+value.insumo+'</td>';
              navTabs += '<td><span class="d-sm-none">Unidad</span>'+value.unidad+'</td>';
              navTabs += '<td><span class="d-sm-none">Clasificación</span>'+value.clasificacion+'</td>';
              navTabs += '<td><span class="d-sm-none">Urgencia</span>'+value.urgencia+'</td>';
            navTabs += '</tr>';
        });
      navTabs += '</tbody>';
    navTabs += '</table>';
  navTabs += '</div>';

  $('#info-tabs').append(navTabs);
};
