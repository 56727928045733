$(window).ready(function(){
	window.aux_contador = sessionStorage.getItem("contador")==null?null:parseInt(sessionStorage.getItem("contador"));
	window.aux_contador_boton = sessionStorage.getItem("contador_boton")==null?null:parseInt(sessionStorage.getItem("contador_boton"));

	if(window.aux_contador!=null && parseInt(window.aux_contador)>0){
		$("#reenviar_codigo").removeClass('cursor-pointer');
		$("#num_telefono").attr("disabled", true);
		$("#btn_cambiar_numero").attr("disabled", true);
		var interval = setInterval(function(){
        	$("#contador_codigo").text(", reenviar nuevamente en "+window.aux_contador);
        	window.aux_contador--;
        	sessionStorage.setItem("contador",window.aux_contador);
        	if(window.aux_contador===0){
        		$("#num_telefono").attr("disabled", false);
				$("#btn_cambiar_numero").attr("disabled", false);
        		$("#reenviar_codigo").addClass('cursor-pointer');
        		$("#contador_codigo").text("");
        		clearInterval(interval);
        	}
        }, 1000);
	}

	$("#reenviar_codigo").mouseenter(function(){
		$(this).css('border-bottom','solid rgba(27, 182, 0, 0.6) 2px');
	});

	$("#reenviar_codigo").mouseleave(function(){
		$(this).css('border-bottom','none');
	});

	if(window.aux_contador_boton!=null && parseInt(window.aux_contador_boton)>0){
		$("#num_telefono").attr("disabled", true);
		$("#btn_cambiar_numero").attr("disabled", true);
		$("#reenviar_codigo").removeClass('cursor-pointer');
		var interval = setInterval(function(){
        	$("#contador_codigo").text(", reenviar nuevamente en "+window.aux_contador_boton);
        	window.aux_contador_boton--;
        	sessionStorage.setItem("contador_boton",window.aux_contador_boton);
        	if(window.aux_contador_boton===0){
        		$("#num_telefono").attr("disabled", false);
				$("#btn_cambiar_numero").attr("disabled", false);
        		$("#reenviar_codigo").addClass('cursor-pointer');
        		$("#contador_codigo").text("");
        		clearInterval(interval);
        	}
        }, 1000);
	}

	$("#no_mi_numero_click").click(function(){
		if($("#no_mi_numero_row").hasClass('d-none')){
			$("#no_mi_numero_click").text("No es mi número? click aqui ▲");
			$("#no_mi_numero_row").removeClass('d-none');
		}else{
			$("#no_mi_numero_click").text("No es mi número? click aqui ▼");
			$("#no_mi_numero_row").addClass('d-none');
		}
	});

	$("#reenviar_codigo").click(function(){
		var contador = sessionStorage.getItem("contador");
		var contador_2 = sessionStorage.getItem("contador_boton");
		if((contador===null || parseInt(contador)<=0) && (contador_2===null || parseInt(contador_2)<=0)){
			window.aux_contador = 0;
			window.aux_contador_boton =0;
			sessionStorage.setItem("contador",60);
		}

		if(window.aux_contador==0 && window.aux_contador_boton==0){
			var d = new Date();
			d = d.getTime();
			$.ajax({
				type: 'POST',
				url: base_url+'/reenviarSMS',
				headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
				data: ({
					date: d
				}),
				success: function(data){
					var msg_negritas ="";
					var error =false;
					var message ="";
					if(data.code==200){
						message = "Se reenvió el código con exito";
						msg_negritas = "Correcto";
					}else if(data.code==204){
						error = true;
						msg_negritas = "Error";
						message = data.message;
					}
					crearMensaje(error,msg_negritas,message,5000);				
				},
				error: function( jqXhr, textStatus, errorThrown ){
					console.log( errorThrown );
				}
			});
			$("#num_telefono").attr("disabled", true);
			$("#btn_cambiar_numero").attr("disabled", true);
			$("#reenviar_codigo").removeClass('cursor-pointer');
			window.aux_contador = parseInt(sessionStorage.getItem("contador"));
			var interval = setInterval(function(){
	        	$("#contador_codigo").text(", reenviar nuevamente en "+window.aux_contador);
	        	window.aux_contador--;
	        	sessionStorage.setItem("contador",window.aux_contador);
	        	if(window.aux_contador===0){
	        		$("#reenviar_codigo").addClass('cursor-pointer');
	        		$("#contador_codigo").text("");
	        		$("#num_telefono").attr("disabled", false);
					$("#btn_cambiar_numero").attr("disabled", false);
	        		clearInterval(interval);
	        	}
	        }, 1000);	
		}
	});

	$("#btn_validar_codigo").click(function(){
		var codigo = $("#codigo").val();
		
		if(codigo=="" || codigo.length < 4){
			crearMensaje(true,"Error","El código no puede estar vacio ni ser menor a 4 carácteres",5000);
		}else{
			$.ajax({
				type: 'POST',
				url: base_url+'/reenviarSMS/validarToken',
				headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
				data: ({
					codigo: codigo,
				}),
				success: function(data){
					if(data.response){
						sessionStorage.clear();
						window.location.href = base_url+"/Cursos";
					}else{
						crearMensaje(true,"Error",data.message,5000);
					}
				},
				error: function( jqXhr, textStatus, errorThrown ){
					console.log( errorThrown );
				}
			});
		}		
	});

	$("#btn_cambiar_numero").click(function(){
		var telefono_change = $("#num_telefono").val();
		telefono_change = telefono_change.replace(/[^0-9]/g,'');
		$("#num_telefono").val(telefono_change);
		if(telefono_change.length == 10){
			var contador = sessionStorage.getItem("contador");
			var contador_2 = sessionStorage.getItem("contador_boton");
			if((contador===null || parseInt(contador)<=0) && (contador_2===null || parseInt(contador_2)<=0)){
				window.aux_contador = 0;
				window.aux_contador_boton =0;
				sessionStorage.setItem("contador_boton",60);
			}

			if(window.aux_contador==0 && window.aux_contador_boton==0){
				var d = new Date();
				d = d.getTime();
				$.ajax({
					type: 'POST',
					url: base_url+'/reenviarSMS/nuevoNumero',
					headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
					data: ({
						telefono: telefono_change,
						date: d
					}),
					success: function(data){
						//console.log(data);
						if(data.response){
							$("#num_telefono_title").text(telefono_change);
							crearMensaje(false,"Correcto","Se ha cambiado correctamente su número y se le ha enviado el nuevo código",5000);
							$("#num_telefono").attr("disabled", true);
							$("#btn_cambiar_numero").attr("disabled", true);
							$("#reenviar_codigo").removeClass('cursor-pointer');
							$("#reenviar_codigo").unbind("mouseenter");
							window.aux_contador_boton = parseInt(sessionStorage.getItem("contador_boton"));
							var interval = setInterval(function(){
					        	$("#contador_codigo").text(", reenviar nuevamente en "+window.aux_contador_boton);
					        	window.aux_contador_boton--;
					        	sessionStorage.setItem("contador_boton",window.aux_contador_boton);
					        	if(window.aux_contador_boton===0){
					        		$("#reenviar_codigo").addClass('cursor-pointer');
					        		$("#reenviar_codigo").bind("mouseenter",function(){
										$(this).css('border-bottom','solid rgba(27, 182, 0, 0.6) 2px');
									});
					        		$("#contador_codigo").text("");
					        		$("#num_telefono").attr("disabled", false);
									$("#btn_cambiar_numero").attr("disabled", false);
					        		clearInterval(interval);
					        	}
					        }, 1000);
						}else{
							crearMensaje(true,"Error",data.message,5000);
						}
					},
					error: function( jqXhr, textStatus, errorThrown ){
						console.log( errorThrown );
					}
				});
			}	
		}else{
			crearMensaje(true,"Error","El número celular ingresado ("+telefono_change+") debe tener 10 dígitos, por ejemplo: 5512345678",5000);
		}
	});
})