$(window).ready(function(){
	//console.log(datos_horario_usuario);
	if(window.location.href===base_url+"/Perfil"){
		$("#horario").removeClass('container');
		$("#horario").addClass('container-fluid');
		$("#schedule_container").addClass("col-11");
		$("#especialidad_container_title_row").addClass("d-none");

		if(tipo_usuario != 'transportista'){
			for (var i=0; i< datos_horario_usuario.length-1;i++){
				$("#"+datos_horario_usuario[i]).closest('.schedule__item').addClass('active');
			}
			setClassDisponivilidad(disponibilidad);
			
			$("#colonia_actual").text("Nombre: "+poli_nombre+", C.P: "+poli_cp);

			if(tipo_usuario == 'especializado'){
				$("#"+id_especialidad).closest('.btn_especialidad').addClass('active');
				$("#"+id_especialidad).val("1");
			}

			$('.btn_especialidad').css('pointer-events','none');
			$('.schedule__item').css('pointer-events','none');
		}else{
			var _position = [_latitud,_longitud];
		}			

		window.is_update = false;
		window.layer_negro = null;
		window.marker = null;
		window.layer_negro_usuario = null;
		window.cp_selected = "";
		var map_voluntario = document.getElementById("map_voluntario");
		var map_transportista = document.getElementById("map_transportista");
		var default_location = [19.430556,-99.133030];
		var cambiar_contrasenia = false;
		var cambiar_telefono = 0;
		var contador_update = sessionStorage.getItem("contador_update")==null?null:parseInt(sessionStorage.getItem("contador_update"));

		/*
		if(contador_update!=null && contador_update > 0 ){
			
		}
		*/

		var map = map_voluntario===null?map_transportista:map_voluntario;

		map = L.map(map.id, { zoomControl:true }).setView(default_location, 11);

		//se le asigna la capa de mapbox
		L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {maxZoom: 19,minZoom: 10,}).addTo(map);

		//se obtienen los poligonos a pintar y se pintan
		var source = $.ajax({
			type: 'GET',
			url: base_url+'/functions_map/getSource'
			}).fail(function(jqXhr,textStatus, errorThrown){
				console.log(errorThrown);
			}).done(function(response){
				source = null;
				if(response.sucess){
					source = response;
				}

				pintarPoligonos(source);
				if(tipo_usuario != 'transportista'){
					setBordeNegro(poly_json,poli_nombre,poli_cp,poli_estatus);
					setFitMap(poli_coords);
				}else{
					addMarkerTransportista();
				}
		});

		function pintarPoligonos(source){
			if(source!==null){
				var featureGroup = L.layerGroup();
				
				for (var key in source) {
					if(typeof(source[key].polygon)!=="undefined"){
						var lineas = [{
						    "type":source[key].type,
						    "coordinates": JSON.parse(source[key].polygon)
						}];

						var color = "#f5a623";
						if(map_transportista===null){
							var estatusColonia = 'Vacío';
							var nombre = source[key].nombre;
							var cp = source[key].cp;

							switch(source[key].color) {
							  case "rojo":
							    color = "#dc0000";
							    estatusColonia = 'Vacío';
							    break;
							  case "amarillo":
							    color = "#ffd60b";
							    estatusColonia = 'Incompleto';
							    break;
							  case "verde":
							    color = "#2ec409";
							    estatusColonia = 'Completo';
							    break;
							  case "gris":
							    color = "#9e9e9e";
							    estatusColonia = '';
							    break;    
							}
						}
						

						var estilo = {
						    "fillColor": color,
						    "weight": 1,
						    "fillOpacity": 0.5,
						    "color": "#FFFFFF",
						    "opacity": 0.8	
						};
						
		  				var polygon = L.geoJSON(lineas, {
		    				style: estilo,
						});
						
		  				if(map_transportista===null){
			  				var contenidoPopUp = getContenidoPopup(estatusColonia,nombre,cp);
			  				
			  				var popup =  L.popup({closeButton:false})
			  					.setContent(contenidoPopUp);

							polygon.bindPopup(popup);

							polygon.on('mouseover', function (e) {this.openPopup();});
							polygon.on('mouseout', function (e) {this.closePopup();});
							polygon.on('click', function (e) {
								clickColonia(e);	
							});
						}	
						polygon.addTo(featureGroup);
						
					}
				}
				featureGroup.addTo(map);
			}
		}

		function getContenidoPopup(estatusColonia,nombre,cp) {
			let content = '<div class="widget">';

		  	content += '<h4>' + nombre + '</h4>';
			content += '<p>CP: ' + cp + ' | ' + estatusColonia + ' </p>';
			content += '</div>';

			return content;
		}

		function clickColonia(poligono){
			if(window.is_update){
				var contenido_popup = poligono.target._popup._content;
				var regex = /:(\s\d+)/g;
				var array_results = regex.exec(contenido_popup);
				var cp = array_results[1].trim();
				window.cp_selected = cp;
				$.get(base_url+'/functions_map/getDatosColoniaByCp?cp='+ cp, function(data, textStatus, xhr) {
					if(textStatus === 'success'){
						if(window.layer_negro!==null){
							map.removeLayer(window.layer_negro);
						}
						$("#colonia_edicion").text("Nombre: "+data.colonia_alcaldia+", C.P: "+cp);
						$("#informacion_colonia").removeClass("d-none");
						document.getElementById('informacion_colonia').scrollIntoView();
						var geometry = poligono.target.toGeoJSON().features[0].geometry;
						var cordenadas = geometry.coordinates;
						setBordeNegro(geometry,data.colonia_alcaldia,cp,data.status);
						setFitMap(cordenadas);
					}
				});

				$.ajax({
					type: 'GET',
					url: base_url+'/functions_map/getDisponibilidadByCp',
					data: ({cp: cp}),
					success: function(data){
						if(data.sucess){
							setClassDisponivilidad(data);
						}else{
							setClassDisponivilidad(disponibilidad);
						}
					},
					error: function( jqXhr, textStatus, errorThrown ){
						console.log( errorThrown );
					}
				});
			}
			
		}

		function setBordeNegro (geometry,nombre,cp,status){
			var contenidoPopUp = getContenidoPopup(status,nombre,cp);
			var popup =  L.popup({closeButton:false})
					.setContent(contenidoPopUp);

		    let bordeNegro = L.geoJson([geometry], {
		        style: {
		            color: "#000",
		            weight: 2
		        }
		    });

		    bordeNegro.bindPopup(popup);
			bordeNegro.on('mouseover', function (e) {this.openPopup();});
			bordeNegro.on('mouseout', function (e) {this.closePopup();});

			map.addLayer(bordeNegro);
			window.layer_negro = bordeNegro;
			if(window.layer_negro_usuario===null){
				window.layer_negro_usuario = bordeNegro;
			}
		}

		function setFitMap(cordenadas){
			var leaflet_polygon = L.polygon(cordenadas);
			var bounds_polygon = leaflet_polygon.getBounds();
			var bounds_array = [];
			for (bound in bounds_polygon){
				let tipo = typeof(bounds_polygon[bound]);
				if(tipo !== "function"){
					let lat = bounds_polygon[bound].lat;
					let lng = bounds_polygon[bound].lng;
					bounds_array.push([lng,lat]);
				}
			}
			map.fitBounds(bounds_array);
		}

		function addMarkerTransportista(){
			var color_marcador = 'rgba(27,182,0,0.3)';

			var estilos_marcador = `
	          background-color: ${color_marcador};
	          width: 6.2rem;
	          height: 6.2rem;
	          display: block;
	          left: -3.1rem;
	          top: -3.1rem;
	          position: relative;
	          border-radius: 6.2rem;
	          transform: rotate(45deg);
	          border: 1px solid #FFFFFF`;

	        var icon = L.divIcon({
	          className: "marcador-sentika",
	          iconAnchor: [0, 24],
	          labelAnchor: [-6, 0],
	          popupAnchor: [0, -36],
	          html: `<span style="${estilos_marcador}" />`
	        }); 
	        
	        window.marker = new L.marker(_position, {
	            icon: icon,
	            draggable: false
	        });

	        window.marker.on('dragend', function(event) {
	          var position = marker.getLatLng();
	          //console.log(position);
	          marker.setLatLng(position, {
	            draggable: true
	          }).bindPopup(position).update();
	          $("#Latitude").val(position.lat);
	          $("#Longitude").val(position.lng).keyup();
	        }); 

	        $("#Latitude, #Longitude").change(function() {
	        	var latitude = isNaN(parseFloat($("#Latitude").val()))?19.430556:parseFloat($("#Latitude").val());
	          	var longitude = isNaN(parseFloat($("#Longitude").val()))?-99.133030:parseFloat($("#Longitude").val());
	          	var position = [latitude,longitude];
	          	
		        window.marker.setLatLng(position, {
		        	draggable: true
		        }).bindPopup(position).update();
	          	map.panTo(position);
	        });

	        map.on('zoomend', function() {
	            var zoom_level = map.getZoom();
	            //console.log('zoomend: ', zoom_level );
	            if( zoom_level === 9 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '3em',
	                                'height': '3em',
	                                'border-radius': '3em',
	                                'top': '-1.5em',
	                                'left': '-1.5em'
	                            });
	            }
	            else if( zoom_level === 10 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '4em',
	                                'height': '4em',
	                                'border-radius': '4em',
	                                'top': '-2em',
	                                'left': '-2em'
	                            });
	            }
	            if( zoom_level === 11 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '6.2em',
	                                'height': '6.2em',
	                                'border-radius': '6.2em',
	                                'top': '-3.1em',
	                                'left': '-3.1em'
	                            });
	            }
	            if( zoom_level === 12 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '10em',
	                                'height': '10em',
	                                'border-radius': '10em',
	                                'top': '-5em',
	                                'left': '-5em'
	                            });
	            }
	            else if( zoom_level === 13 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '15em',
	                                'height': '15em',
	                                'border-radius': '15em',
	                                'top': '-7.5em',
	                                'left': '-7.5em'
	                            });
	            }
	            else if( zoom_level === 14 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '25em',
	                                'height': '25em',
	                                'border-radius': '25em',
	                                'top': '-12.5em',
	                                'left': '-12.5em'
	                            });
	            }
	            else if( zoom_level === 15 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '45em',
	                                'height': '45em',
	                                'border-radius': '45em',
	                                'top': '-22.5em',
	                                'left': '-22.5em'
	                            });
	            }
	            else if( zoom_level === 16 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '80em',
	                                'height': '80em',
	                                'border-radius': '80em',
	                                'top': '-40em',
	                                'left': '-40em'
	                            });
	            }
	            else if( zoom_level === 17 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '120em',
	                                'height': '120em',
	                                'border-radius': '120em',
	                                'top': '-60em',
	                                'left': '-60em'
	                            });
	            }
	            else if( zoom_level === 18 )
	            {
	                $('.marcador-sentika span').css({
	                                'width': '170em',
	                                'height': '170em',
	                                'border-radius': '170em',
	                                'top': '-85em',
	                                'left': '-85em'
	                            });
	            }
	        });

        	map.addLayer(window.marker);

		}

			   

		$("#btn_editar_perfil").click(function(){
			if(window.is_update){
				window.is_update = false;
				$(".span_edit_field").removeClass('d-none');
				$(".input_edit_field").addClass('d-none');
				$("#btn_guardar_perfil").addClass('d-none');
				$("#informacion_colonia").addClass("d-none");
				$(this).css('background-color','#71415a');
				$(this).text('Editar Perfil');
				if(tipo_usuario != 'transportista'){
					var coordinates = window.layer_negro_usuario.toGeoJSON().features[0].geometry.coordinates;
					setFitMap(coordinates);

					for (var i=0; i< datos_horario_usuario.length-1;i++){
						$("#"+datos_horario_usuario[i]).closest('.schedule__item').addClass('active');
					}

					map.removeLayer(window.layer_negro);
					window.layer_negro = window.layer_negro_usuario;
					map.addLayer(window.layer_negro_usuario);
					setClassDisponivilidad(disponibilidad);
					window.cp_selected = "";

					if(tipo_usuario != 'especializado'){
						$("#"+id_especialidad).closest('.btn_especialidad').addClass('active');
					}
					
				}else{
					$('#Latitude').val(_latitud);
					$('#Longitude').val(_longitud);
					$('#tipo_transporte').val(_medio_transporte);
					window.marker.dragging.disable();
					window.marker.setLatLng(_position);
					map.setView(default_location, 11);
				}
				
				$('.schedule__item').css('pointer-events','none');
				$('.btn_especialidad').css('pointer-events','none');
				$('.btn_especialidad').removeClass('active');
				$('.schedule__item').removeClass('active');
				
				$('#nombre').val(_nombre);
				$('#apellido_paterno').val(_apellido_paterno);
				$('#apellido_materno').val(_apellido_materno);
				$('#fecha_nacimiento').val(_fecha_nacimiento);
				$('#email').val(_email);
				
			}else{
				window.is_update = true;
				$(".span_edit_field").addClass('d-none');
				$(".input_edit_field").removeClass('d-none');
				$("#btn_guardar_perfil").removeClass('d-none');
				$(this).css('background-color','#dc0000');
				$(this).text('Cancelar');
				map.setView(default_location, 11);
				if(tipo_usuario == 'transportista'){
					window.marker.dragging.enable();
					
				}
				$('.schedule__item').css('pointer-events','auto');
				$('.btn_especialidad').css('pointer-events','auto');
			}
		});

		$("#btn_guardar_perfil").click(function(){
			//variables a mandar en el put
			/****************Variables generales******************/
			var nombre = $('#nombre').val();
			var apellido_paterno = $('#apellido_paterno').val();
			var apellido_materno = $('#apellido_materno').val();
			var fecha_nacimiento = $('#fecha_nacimiento').val();
			var email = $('#email').val();
			//var telefono = $('#telefono').val();

			/****************Variables fija_esp******************/
			var cp = "";
			var slots_array = [];
			//especializado
			var id_input_seleccionado ="";
			/****************Variables transportista******************/
			var latitud = 0;
			var longitud = 0;
			var tipo_transporte ="";
			//variables auxiliares
			var btn_especialidad_precionado = true;
			var slot_seleccionado = true;
			var tipo_transporte_ok =true;
			var lat_lon_regex_ok = true;
			var lat_lon_minmax_ok = true;

			var slots = $(".slot-dia");

			if(tipo_usuario != 'transportista'){
				cp = window.cp_selected==""?poli_cp:window.cp_selected;
				slot_seleccionado = false;
				for(var i=0;i<slots.length;i++){
					var slot_classes = slots[i].classList;
					if(slot_classes.contains("active")){
						slot_seleccionado=true;
						slots_array.push(slots[i].firstElementChild.htmlFor);
					}
				}

				if(tipo_usuario == 'especializado'){
					var botones_especialidad = $(".btn_especialidad");
					btn_especialidad_precionado = false;

					botones_especialidad.each(function(indice,boton){
						var hijos = boton.firstElementChild.children;
						var hijos_tam = hijos.length;

						for (var i=0; i< hijos_tam; i++){
							var tipo = hijos.item(i).nodeName;
							if(tipo==="INPUT"){

								if(hijos.item(i).value > 0){
									id_input_seleccionado =  hijos.item(i).id;
									btn_especialidad_precionado = true;
								}
								break;
							}
						}
					});
				}
			}else{
				tipo_transporte = $("#tipo_transporte").val();
				
				if(tipo_transporte!=="" && (tipo_transporte=="Bicicleta" || tipo_transporte=="Motocicleta")){
					//se valida la longitud y la latitud
                    latitud = $('#Latitude').val();
                    longitud = $('#Longitude').val();
                    //se quita lo que no sirve
                    latitud = latitud.replace(/[^0-9.-]/g,'');
                    longitud = longitud.replace(/[^0-9.-]/g,'');
                    $('#Latitude').val(latitud);
                    $('#Longitude').val(longitud);
                    //regex que valida que puede empezar con un - seguido de n cantidad de números y despues puede tener un .seguido de n cantidan de números
                    var regex_latlon = RegExp('^-?[0-9]+(.?[0-9]+)$');
                    if(regex_latlon.test(latitud) && regex_latlon.test(latitud)){
                    	//se valida que la latitud y longitud este entre sus valores maximos y minimos
                        if(latitud <-90 || latitud >90 || longitud <-180 || longitud >180){
                        	lat_lon_minmax_ok = false;
                        }
                    }else{
                    	lat_lon_regex_ok =false;
                    }
				}else{
					tipo_transporte_ok = false;
				}
			}
			
			if(tipo_transporte_ok){
				if(lat_lon_regex_ok){
					if(lat_lon_minmax_ok){
						if(btn_especialidad_precionado){
							if(slot_seleccionado){
								fecha_nacimiento = fecha_nacimiento.replace(/[^0-9-]/g,'');
								$("#fecha_nacimiento").val(fecha_nacimiento);
								var regex_fecha_nacimiento = RegExp('([0-9]{4})-([0-9]{2})-([0-9]{2})$');
								if(regex_fecha_nacimiento.test(fecha_nacimiento)){
									email = email.replace(/\s/g,'');
									$('#email').val(email);
									var regex_email = RegExp('(.+)@(.+)$');
									if(regex_email.test(email)){	
										$.ajax({
											type: 'POST',
											url: base_url+'/Perfil/Actualizar',
											headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
											data: ({
													_method: 'PUT',
													id:id,
													nombre:nombre,
													apellido_paterno:apellido_paterno,
													apellido_materno:apellido_materno,
													fecha_nacimiento:fecha_nacimiento,
													//telefono:telefono,
													email:email,
													cp:cp,
													slots_array:slots_array,
													especialidad:id_input_seleccionado,
													latitud:latitud,
													longitud:longitud,
													tipo_transporte:tipo_transporte
												}),
											success: function(data){
												if(data.code==200){
													window.location.href = base_url+"/Perfil";
												}else{
													crearMensaje(true,"Error",data.response);
												}
											},
											error: function( jqXhr, textStatus, errorThrown ){
												console.log( errorThrown );
											}
										});
										
									}else{
										crearMensaje(true,"Error","El correo ingresado ("+email+") debe tener la estructura adecuada",5000);
									}
								}else{
									crearMensaje(true,"Error","La fecha ingresada ("+fecha_nacimiento+") debe tener el formato aaaa-mm-dd (año-mes-dia) ejemplo 1995-05-10",6000);
								}
							}else{
								crearMensaje(true,"Error","Debes de seleccionar por lo menos 1 horario");
								document.getElementById('horario').scrollIntoView();
							}
						}else{
							document.getElementById('row_especialidades').scrollIntoView();
							crearMensaje(true,"Error","Selecciona una Especialidad para continuar");
						}
					}else{
						crearMensaje(true,"Error","El valor de la Latitud o el de la longitud no son validos",5000);
					}
				}else{
					crearMensaje(true,"Error","El valor de la latitud o longitud no tiene la estructura adecuada",5000);
				}
			}else{
				crearMensaje(true,"Error","Debes seleccionar un tipo de transporte correcto",5000);
			}	
			
		});	


		function setClassDisponivilidad(disponibilidad){
			//console.log(disponibilidad);
			if(disponibilidad!==null && disponibilidad.sucess===true){
				/*Lunes*/
				var clase = disponibilidad.lunes_manana === 0?"empty": disponibilidad.lunes_manana < 5?"incomplete":"full";
				$("label[for='ayuda_lunes_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_lunes_manana']").parent().addClass(clase);
				$("label[for='ayuda_lunes_manana'] span").text(disponibilidad.lunes_manana+" Voluntarios");

				var clase = disponibilidad.lunes_tarde === 0?"empty": disponibilidad.lunes_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_lunes_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_lunes_tarde']").parent().addClass(clase);
				$("label[for='ayuda_lunes_tarde'] span").text(disponibilidad.lunes_tarde+" Voluntarios");

				var clase = disponibilidad.lunes_noche === 0?"empty": disponibilidad.lunes_noche < 5?"incomplete":"full";
				$("label[for='ayuda_lunes_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_lunes_noche']").parent().addClass(clase);
				$("label[for='ayuda_lunes_noche'] span").text(disponibilidad.lunes_noche+" Voluntarios");

				var clase = disponibilidad.lunes_madrugada === 0?"empty": disponibilidad.lunes_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_lunes_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_lunes_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_lunes_madrugada'] span").text(disponibilidad.lunes_madrugada+" Voluntarios");

				/*martes*/
				var clase = disponibilidad.martes_manana === 0?"empty": disponibilidad.martes_manana < 5?"incomplete":"full";
				$("label[for='ayuda_martes_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_martes_manana']").parent().addClass(clase);
				$("label[for='ayuda_martes_manana'] span").text(disponibilidad.martes_manana+" Voluntarios");

				var clase = disponibilidad.martes_tarde === 0?"empty": disponibilidad.martes_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_martes_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_martes_tarde']").parent().addClass(clase);
				$("label[for='ayuda_martes_tarde'] span").text(disponibilidad.martes_tarde+" Voluntarios");

				var clase = disponibilidad.martes_noche === 0?"empty": disponibilidad.martes_noche < 5?"incomplete":"full";
				$("label[for='ayuda_martes_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_martes_noche']").parent().addClass(clase);
				$("label[for='ayuda_martes_noche'] span").text(disponibilidad.martes_noche+" Voluntarios");

				var clase = disponibilidad.martes_madrugada === 0?"empty": disponibilidad.martes_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_martes_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_martes_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_martes_madrugada'] span").text(disponibilidad.martes_madrugada+" Voluntarios");

				/*miercoles*/
				var clase = disponibilidad.miercoles_manana === 0?"empty": disponibilidad.miercoles_manana < 5?"incomplete":"full";
				$("label[for='ayuda_miercoles_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_miercoles_manana']").parent().addClass(clase);
				$("label[for='ayuda_miercoles_manana'] span").text(disponibilidad.miercoles_manana+" Voluntarios");

				var clase = disponibilidad.miercoles_tarde === 0?"empty": disponibilidad.miercoles_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_miercoles_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_miercoles_tarde']").parent().addClass(clase);
				$("label[for='ayuda_miercoles_tarde'] span").text(disponibilidad.miercoles_tarde+" Voluntarios");

				var clase = disponibilidad.miercoles_noche === 0?"empty": disponibilidad.miercoles_noche < 5?"incomplete":"full";
				$("label[for='ayuda_miercoles_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_miercoles_noche']").parent().addClass(clase);
				$("label[for='ayuda_miercoles_noche'] span").text(disponibilidad.miercoles_noche+" Voluntarios");

				var clase = disponibilidad.miercoles_madrugada === 0?"empty": disponibilidad.miercoles_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_miercoles_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_miercoles_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_miercoles_madrugada'] span").text(disponibilidad.miercoles_madrugada+" Voluntarios");

				/*jueves*/
				var clase = disponibilidad.jueves_manana === 0?"empty": disponibilidad.jueves_manana < 5?"incomplete":"full";
				$("label[for='ayuda_jueves_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_jueves_manana']").parent().addClass(clase);
				$("label[for='ayuda_jueves_manana'] span").text(disponibilidad.jueves_manana+" Voluntarios");

				var clase = disponibilidad.jueves_tarde === 0?"empty": disponibilidad.jueves_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_jueves_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_jueves_tarde']").parent().addClass(clase);
				$("label[for='ayuda_jueves_tarde'] span").text(disponibilidad.jueves_tarde+" Voluntarios");

				var clase = disponibilidad.jueves_noche === 0?"empty": disponibilidad.jueves_noche < 5?"incomplete":"full";
				$("label[for='ayuda_jueves_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_jueves_noche']").parent().addClass(clase);
				$("label[for='ayuda_jueves_noche'] span").text(disponibilidad.jueves_noche+" Voluntarios");

				var clase = disponibilidad.jueves_madrugada === 0?"empty": disponibilidad.jueves_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_jueves_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_jueves_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_jueves_madrugada'] span").text(disponibilidad.jueves_madrugada+" Voluntarios");

				/*viernes*/
				var clase = disponibilidad.viernes_manana === 0?"empty": disponibilidad.viernes_manana < 5?"incomplete":"full";
				$("label[for='ayuda_viernes_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_viernes_manana']").parent().addClass(clase);
				$("label[for='ayuda_viernes_manana'] span").text(disponibilidad.viernes_manana+" Voluntarios");

				var clase = disponibilidad.viernes_tarde === 0?"empty": disponibilidad.viernes_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_viernes_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_viernes_tarde']").parent().addClass(clase);
				$("label[for='ayuda_viernes_tarde'] span").text(disponibilidad.viernes_tarde+" Voluntarios");

				var clase = disponibilidad.viernes_noche === 0?"empty": disponibilidad.viernes_noche < 5?"incomplete":"full";
				$("label[for='ayuda_viernes_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_viernes_noche']").parent().addClass(clase);
				$("label[for='ayuda_viernes_noche'] span").text(disponibilidad.viernes_noche+" Voluntarios");

				var clase = disponibilidad.viernes_madrugada === 0?"empty": disponibilidad.viernes_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_viernes_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_viernes_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_viernes_madrugada'] span").text(disponibilidad.viernes_madrugada+" Voluntarios");

				/*sabado*/
				var clase = disponibilidad.sabado_manana === 0?"empty": disponibilidad.sabado_manana < 5?"incomplete":"full";
				$("label[for='ayuda_sabado_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_sabado_manana']").parent().addClass(clase);
				$("label[for='ayuda_sabado_manana'] span").text(disponibilidad.sabado_manana+" Voluntarios");

				var clase = disponibilidad.sabado_tarde === 0?"empty": disponibilidad.sabado_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_sabado_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_sabado_tarde']").parent().addClass(clase);
				$("label[for='ayuda_sabado_tarde'] span").text(disponibilidad.sabado_tarde+" Voluntarios");

				var clase = disponibilidad.sabado_noche === 0?"empty": disponibilidad.sabado_noche < 5?"incomplete":"full";
				$("label[for='ayuda_sabado_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_sabado_noche']").parent().addClass(clase);
				$("label[for='ayuda_sabado_noche'] span").text(disponibilidad.sabado_noche+" Voluntarios");

				var clase = disponibilidad.sabado_madrugada === 0?"empty": disponibilidad.sabado_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_sabado_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_sabado_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_sabado_madrugada'] span").text(disponibilidad.sabado_madrugada+" Voluntarios");

				/*domingo*/
				var clase = disponibilidad.domingo_manana === 0?"empty": disponibilidad.domingo_manana < 5?"incomplete":"full";
				$("label[for='ayuda_domingo_manana']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_domingo_manana']").parent().addClass(clase);
				$("label[for='ayuda_domingo_manana'] span").text(disponibilidad.domingo_manana+" Voluntarios");

				var clase = disponibilidad.domingo_tarde === 0?"empty": disponibilidad.domingo_tarde < 5?"incomplete":"full";
				$("label[for='ayuda_domingo_tarde']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_domingo_tarde']").parent().addClass(clase);
				$("label[for='ayuda_domingo_tarde'] span").text(disponibilidad.domingo_tarde+" Voluntarios");

				var clase = disponibilidad.domingo_noche === 0?"empty": disponibilidad.domingo_noche < 5?"incomplete":"full";
				$("label[for='ayuda_domingo_noche']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_domingo_noche']").parent().addClass(clase);
				$("label[for='ayuda_domingo_noche'] span").text(disponibilidad.domingo_noche+" Voluntarios");

				var clase = disponibilidad.domingo_madrugada === 0?"empty": disponibilidad.domingo_madrugada < 5?"incomplete":"full";
				$("label[for='ayuda_domingo_madrugada']").parent().removeClass("empty incomplete full");
				$("label[for='ayuda_domingo_madrugada']").parent().addClass(clase);
				$("label[for='ayuda_domingo_madrugada'] span").text(disponibilidad.domingo_madrugada+" Voluntarios");
			}
		}

		$("#btn_regresar_cursos").click(function(){
			window.location.href = base_url+"/Cursos";
		});

		$("#btn_cambiar_contrasenia").click(function(){
			if(cambiar_contrasenia){
				var contrasenia_actual = $("#password_old").val();
				var contrasenia_nueva = $("#password_new").val();
				var contrasenia_nueva_otra_vez = $("#password_new_again").val();

				if(contrasenia_actual.length>=8 && contrasenia_actual.length<=12 && contrasenia_nueva.length>=8 && contrasenia_nueva.length<=12){
					if(contrasenia_nueva===contrasenia_nueva_otra_vez){
						$.ajax({
							type: 'POST',
							url: base_url+'/Perfil/ActualizarContraseña',
							headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
							data: ({
									_method: 'PUT',
									contrasenia_actual:contrasenia_actual,
									contrasenia_nueva:contrasenia_nueva,
									contrasenia_nueva_otra_vez:contrasenia_nueva_otra_vez,
								}),
							success: function(data){
								if(data.code==200){
									window.location.href = base_url+"/Perfil";
								}else{
									crearMensaje(true,"Error",data.response);
								}
							},
							error: function( jqXhr, textStatus, errorThrown ){
								console.log( errorThrown );
							}
						});
					}else{
						crearMensaje(true,"Error","La contraseña nueva ingresada no coincide con la de Confirma contraseña",5000);
					}
				}else{
					crearMensaje(true,"Error","La contraseña actual y la nueva deben de tener al menos 8 caracteres y no más de 12 caracteres",5000);
				}	
			}else{
				cambiar_contrasenia = true;
				$("#cambiar_contrasenia_fields").removeClass('d-none');
				$(this).text('Guardar Contraseña');
				$("#btn_cancelar_cambios_contrasenia_telefono").removeClass('d-none');
				$("#btn_cambiar_telefono").addClass('d-none');
			}
		});

		$("#btn_cancelar_cambios_contrasenia_telefono").click(function(){
			$("#password_old").val("");
			$("#password_new").val("");
			$("#password_new_again").val("");

			cambiar_contrasenia = false;
			cambiar_telefono = 0;

			$("#cambiar_contrasenia_fields").addClass('d-none');
			$("#cambiar_telefono_fields").addClass('d-none');

			$("#btn_cambiar_contrasenia").text('Cambiar Contraseña');
			$("#btn_cambiar_telefono").text('Cambiar Telefono');


			$("#btn_cancelar_cambios_contrasenia_telefono").addClass('d-none');
			$("#btn_cambiar_telefono").removeClass('d-none');
			$("#btn_cambiar_contrasenia").removeClass('d-none');

			$("#col_codigo_sms").addClass('d-none');
			$("#btn_validar_codigo_update").addClass('d-none');
		});


		$("#btn_cambiar_telefono").click(function(){
			switch(cambiar_telefono) {
				//caso de presionar el boton de cambiar telefono por primera vez
			  case 0:
			    $("#cambiar_telefono_fields").removeClass('d-none');
			    $("#btn_cambiar_contrasenia").addClass('d-none');
			    $("#btn_cancelar_cambios_contrasenia_telefono").removeClass('d-none');
			    $(this).text('Enviar Código SMS');
			    cambiar_telefono = 1;
			    if(contador_update!=null && contador_update > 0 ){
			    	$("#btn_cambiar_telefono").css('pointer-events','none');
					$("#btn_cambiar_telefono").attr("disabled", true);
					var interval = setInterval(function(){
			        	$("#btn_cambiar_telefono").text("Reenviar nuevamente en "+contador_update);
			        	contador_update--;
			        	sessionStorage.setItem("contador_update",contador_update);
			        	if(contador_update===0){
			        		$("#btn_cambiar_telefono").css('pointer-events','auto');
							$("#btn_cambiar_telefono").attr("disabled", false);
							$("#btn_cambiar_telefono").text("Enviar Código SMS");
			        		clearInterval(interval);
			        	}
			        }, 1000);
			    }
			    break;
			  case 1:
			  	var telefono_actual = $('#telefono_actual').val();
		    	var telefono_nuevo = $('#telefono_nuevo').val();
		    	telefono_nuevo = telefono_nuevo.replace(/[^0-9]/g,'');
				$("#telefono_nuevo").val(telefono_nuevo);
		    	if(telefono_actual.length > 0){
		    		if(telefono_actual==telefono_usuario){
		    			if(telefono_nuevo.length == 10){
		    				if(telefono_actual!=telefono_nuevo){
		    					if(contador_update===null || contador_update<=0){
			    					contador_update=0;
			    					sessionStorage.setItem("contador_update",60);
			    				}

			    				if(contador_update ==0){
			    					var d = new Date();
									d = d.getTime();
									$.ajax({
										type: 'POST',
										url: base_url+'/Perfil/ActualizarTelefono/enviarSMS',
										headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
										data: ({
											telefono_actual: telefono_actual,
											telefono_nuevo: telefono_nuevo,
											date: d
										}),
										success: function(data){
											if(data.code==200){
												$("#col_codigo_sms").removeClass('d-none');
												$("#btn_validar_codigo_update").removeClass('d-none');
												contador_update=60;
												crearMensaje(false,"Correcto",data.response,8000);
												$("#btn_cambiar_telefono").css('pointer-events','none');
												$("#btn_cambiar_telefono").attr("disabled", true);
												var interval = setInterval(function(){
										        	$("#btn_cambiar_telefono").text("Reenviar nuevamente en "+contador_update);
										        	contador_update--;
										        	sessionStorage.setItem("contador_update",contador_update);
										        	if(contador_update===0){
										        		$("#btn_cambiar_telefono").css('pointer-events','auto');
														$("#btn_cambiar_telefono").attr("disabled", false);
														$("#btn_cambiar_telefono").text("Reenviar Código");
										        		clearInterval(interval);
										        	}
										        }, 1000);
											}else{
												contador_update=0;
			    								sessionStorage.setItem("contador_update",0);
												crearMensaje(true,"Error",data.response,5000);
											}
										},
										error: function( jqXhr, textStatus, errorThrown ){
											console.log( errorThrown );
										}
									});
			    				}else{
			    					crearMensaje(true,"Error","Espere el tiempo indicado antes de poder enviar nuevamente el mensaje",5000);
			    				}
		    				}else{
		    					crearMensaje(true,"Error","El número celular nuevo ingresado tiene que ser diferente al número celular actual",5000);	
		    				}
		    			}else{
		    				crearMensaje(true,"Error","El número celular nuevo ingresado ("+telefono_nuevo+") debe tener 10 dígitos",5000);
		    			}
		    		}else{
		    			crearMensaje(true,"Error","El número celular actual no coincide",5000);	
		    		}
		    	}else{
		    		crearMensaje(true,"Error","El número celular actual no puede estar vacio",5000);
		    	}

			    break;
			  default:
			    $("#btn_cancelar_cambios_contrasenia_telefono").click();
			    break;
			}
		});

		$("#btn_validar_codigo_update").click(function(){
			var codigo = $("#codigo_sms").val();
			if(codigo=="" || codigo.length < 4){
				crearMensaje(true,"Error","El código no puede estar vacio ni ser menor a 4 carácteres",5000);
			}else{
				$.ajax({
					type: 'POST',
					url: base_url+'/Perfil/ActualizarTelefono',
					headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
					data: ({
						_method: 'PUT',
						codigo: codigo
					}),
					success: function(data){
						if(data.code==200){
							sessionStorage.clear();
							window.location.href = base_url+"/Perfil";
						}else{
							crearMensaje(true,"Error",data.message,5000);
						}
					},
					error: function( jqXhr, textStatus, errorThrown ){
						console.log( errorThrown );
					}
				});
			}
		});

	}
});